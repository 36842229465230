import React, { useEffect, useState } from "react";
import "../../ui/components/estratificacao-defeitos/estratificacoes.css";
import { BsSearch } from "react-icons/bs";
import BarChat from "../../ui/components/grafico";
import { GoGraph } from "react-icons/go";
import { AiFillInfoCircle } from "react-icons/ai";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import {
  GetMensalByForn,
  GetPQMFEstratificacaoOrigem,
  GetPQMFEstratificacaoPrincipaisDefeitos,
  GetPQMFEstratificacaoPrincipaisDefeitosOrigens,
  GetPQMFEstratificacaoSeveridade,
  GetSuppliers,
  GetSuppliersByUnity,
} from "../../data/services";
import { GetYearsArry } from "../../helpers/helpers";
import { useAuth } from "../../context/auth";
import { GetSuppliersById } from "../../data/services";
import secureLocalStorage from "react-secure-storage";
import { ChartData } from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const ErrorsNce = () => {
  const token: any = secureLocalStorage.getItem("access_token");

  const Data: any = [
    // {
    //   id: 1,
    //   year: 2016,
    //   userGain: 5000,
    //   userLost: 823,
    //   title: "teste1",
    // },
    // {
    //   id: 2,
    //   year: 2017,
    //   userGain: 5000,
    //   userLost: 345,
    //   title: "teste2",
    // },
    // {
    //   id: 3,
    //   year: 2018,
    //   userGain: 5000,
    //   userLost: 555,
    //   title: "teste3",
    // },
    // {
    //   id: 4,
    //   year: 2019,
    //   userGain: 5000,
    //   userLost: 4555,
    //   title: "teste4",
    // },
    // {
    //   id: 5,
    //   year: 2020,
    //   userGain: 4300,
    //   userLost: 234,
    //   title: "teste5",
    // },
  ];

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        // display: false,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: 
        {
          type: 'linear',
          position: "right",
          id: "y-axis-2",
          ticks: {
              min: 0                        
          },
        },
      }
    }

  const [forn, setForn] = React.useState([]);
  const [fornItem, setFornItem] = React.useState<any>("Selecione");
  const [units, setUnits] = React.useState([]);
  const [principaisDefeitos, setPrincipaisDefeitos] = React.useState([]);
  const [originDefect, setOriginDefect] = React.useState("Recebimento");
  const [defectsOrigin, setDefectsOrigin] = React.useState([]);
  const [dataOriginDefects, setDataOriginDefects] = useState<ChartData<"bar">>({
    labels: [],
    datasets: [],
  });

  const [defeitosSeveridade, setDefeitosSeveridade] = React.useState([]);
  const [dataSeveridadeDefeitos, setDataSeveridadeDefeitos] = useState<ChartData<"bar">>({
    labels: [],
    datasets: [],
  });
  // const [severidade, setSeveridade] = useState<any>([]);
  const [severidade, setSeveridade] = React.useState("Crítico");
  const [principaisDefeitosOrigens, setPrincipaisDefeitosOrigens] = React.useState([]);
  const [year, setYear] = useState<any>([]);
  // const [year, setYear] = React.useState("2022");
  const [objectForn, setObjectForn] = React.useState<any>({
    idUnidade: 0,
    idForn: 0
  });

  const [disabledButton, setDisabledButton] = React.useState(true)
  const [anosPesquisa, setAnosPesquisa] = React.useState<number[]>([])

  const [showGrafic, setShowGrafic] = React.useState<boolean>(false);
  const [userData, setUserData] = React.useState({
    labels: Data.map((item: any) => item.title),
  });
  const [defeitosOrigem, setDefeitosOrigem] = React.useState([]);
  const { user: { profile, idFabrica } } = useAuth()

  const [tableGraphic, setTableGraphic] = React.useState<any>()
  const [tableGraphic2, setTableGraphic2] = React.useState<any>()
  const [tableGraphic3, setTableGraphic3] = React.useState<any>()
  const [tableGraphic4, setTableGraphic4] = React.useState<any>()

  // Estado para controlar a visibilidade da tabela1
  const [isTableVisible, setIsTableVisible] = React.useState(false);
  const [isTable2Visible, setIsTable2Visible] = React.useState(false);
  const [isTable3Visible, setIsTable3Visible] = React.useState(false);
  const [isTable4Visible, setIsTable4Visible] = React.useState(false);
 
  // Função para alternar a visibilidade da tabela1
  const toggleTableVisibility = () => {
    setIsTableVisible(!isTableVisible);
  };

  React.useEffect(() => {
    profile === "usuarioFornecedor"
      ? GetSuppliersById(setForn, idFabrica)
      : GetSuppliers(setForn);
    GetSuppliers(setForn);
    GetSuppliersByUnity(setUnits);
    setAnosPesquisa(GetYearsArry(new Date().getFullYear(), 7));
  }, []);

  const FilterItemByName =
    fornItem &&
    units.filter((item: any) => {
      return item.idFornecedor == fornItem;
    });



  const Search = React.useCallback(async () => {
    console.log(year, objectForn?.idForn, objectForn?.idUnidade)
    GetPQMFEstratificacaoPrincipaisDefeitos(
      setPrincipaisDefeitos,
      year ?? 2023,
      objectForn?.idForn,
      objectForn?.idUnidade
    );

    GetPQMFEstratificacaoPrincipaisDefeitosOrigens(
      setPrincipaisDefeitosOrigens,
      year ?? 2023,
      objectForn?.idForn,
      objectForn?.idUnidade
    );

    GetPQMFEstratificacaoSeveridade(
      setDefeitosSeveridade,
      severidade,
      year,
      objectForn?.idForn,
      objectForn?.idUnidade
    );

    GetPQMFEstratificacaoOrigem(
      setDefectsOrigin,
      originDefect,
      year,
      objectForn?.idForn,
      objectForn?.idUnidade
    );

    setShowGrafic(true);
  }, [
    objectForn?.idForn,
    objectForn?.idUnidade,
    originDefect,
    severidade,
    year,
  ]);

  const filterSeveridade = (sev: any) => {
    GetPQMFEstratificacaoSeveridade(
      setDefeitosSeveridade,
      sev,
      year,
      objectForn?.idForn,
      objectForn?.idUnidade
    );
  };

  const filterOrigem = (or: any) => {
    GetPQMFEstratificacaoOrigem(
      setDefectsOrigin,
      or,
      year,
      objectForn?.idForn,
      objectForn?.idUnidade
    );
  };

  useEffect(() => {

    // Pareto de Principais Defeitos

    const result = Object.values(principaisDefeitos.reduce((acc: any, item: any) => {
      const { tipo_defeito, qtd_defeitos, percentual, id_nce } = item;
     
      if (!acc[tipo_defeito]) {
        acc[tipo_defeito] = {
          tipo_defeito,
          qtd_defeitos: parseInt(qtd_defeitos),
          percentual: parseFloat(percentual), // Converte percentual para número
          id_nce: [id_nce] // Adiciona o id_nce em um array
        };
      } else {
        acc[tipo_defeito].qtd_defeitos += parseInt(qtd_defeitos); // Soma qtd_defeitos
        acc[tipo_defeito].percentual += parseFloat(percentual); // Soma percentual
        acc[tipo_defeito].id_nce.push(id_nce); // Adiciona o id_nce ao array
      }
     
      return acc;
    }, {}));

    setTableGraphic(result)
     
    const QTDEFEITOS = result.map((item: any) => {
      return parseInt(item.qtd_defeitos);
    });

    const PERCENTUAL = result.map((item: any) => {
      return parseInt(item.percentual);
    });

    const TIPO_DEFEITO = principaisDefeitos.map((item: any) => {
      return item.tipo_defeito;
    });

    setUserData((prev: any) => ({
      ...prev,
      labels: TIPO_DEFEITO,
      datasets: [{
          type: 'bar',
          yAxisID: "y-axis-1",
          label: 'Valor',
          borderColor: 'rgba(163, 142, 23, 0.8)',
          backgroundColor: 'rgba(163, 142, 23, 0.8)',
          fill: true,
          data: QTDEFEITOS
      }, {
          type: 'line',
          label: 'Percentual Acumulado',
          // yAxisID: 'y-axis-2',
          backgroundColor: 'gray',
          borderColor: 'gray',
          borderWidth: 2,
          fill: false,
          data: PERCENTUAL
        }],
      }));
     
    // Pareto de Principais Origens de Defeitos

    const groupedData = principaisDefeitosOrigens.reduce((acc: any, curr: any) => {
      // Se a origem já existir no acumulador, soma os dados
      const existing = acc.find((item: { origem: any; }) => item.origem === curr.origem);
      if (existing) {
        existing.qtd_defeitos = (parseInt(existing.qtd_defeitos) + parseInt(curr.qtd_defeitos)).toString();
        existing.percentual = (parseFloat(existing.percentual) + parseFloat(curr.percentual)).toFixed(2);
        existing.id_nce.push(curr.id_nce); // Adiciona o id_nce na lista
      } else {
        // Caso contrário, adiciona o novo item ao acumulador
        acc.push({
          origem: curr.origem,
          qtd_defeitos: curr.qtd_defeitos,
          percentual: curr.percentual,
          id_nce: [curr.id_nce] // Coloca o id_nce em uma lista
        });
      }
      return acc;
    }, []);   

    setTableGraphic2(groupedData)

    const ORIGEM = groupedData?.map((item: any) => item.origem);


    const PERCENTUAL_ORIGEM = groupedData?.map((item: any) =>
      parseInt(item.percentual)
    );

    const TOTAL_DEFEITOS = groupedData?.map((item: any) =>
      parseInt(item.qtd_defeitos)
    );

    setDefeitosOrigem((prev: any) => ({
      ...prev,
      labels: ORIGEM,
      datasets: [
        {
          type: 'bar',
          yAxisID: "y-axis-1",
          label: 'Valor',
          borderColor: 'rgba(163, 142, 23, 0.8)',
          backgroundColor: 'rgba(163, 142, 23, 0.8)',
          fill: true,
          data: TOTAL_DEFEITOS
        },
        {
          type: 'line',
          label: 'Percentual Acumulado',
          // yAxisID: "y-axis-2",
          backgroundColor: 'gray',
          borderColor: 'gray',
          borderWidth: 2,
          fill: false,
          data: PERCENTUAL_ORIGEM
        },
      ],
    }));

    // Pareto de Fornecedores por Severidade de Defeito

    // Estrutura de armazenamento para agrupar os dados por unidade e severidade
    const FornServData: Record<string, { [key: string]: number }> = {};
  
    // Processando os dados
    defeitosSeveridade.forEach(({ fornecedor, severidade, qtd_defeitos }) => {
      if (!FornServData[fornecedor]) {
        FornServData[fornecedor] = { CRITICO: 0, GRAVE: 0, TOLERAVEL: 0 };
      }
      FornServData[fornecedor][severidade] += parseInt(qtd_defeitos);
    }); 


    const FornServData2 = defeitosSeveridade.reduce((acc: any, curr: any) => {
      // Se a severidade já existir no acumulador, soma os dados
      const existingSev = acc.find((item: { severidade: any; }) => item.severidade === curr.severidade);
      if (existingSev) {
        existingSev.qtd_defeitos = (parseInt(existingSev.qtd_defeitos) + parseInt(curr.qtd_defeitos)).toString();
        existingSev.percentual = (parseFloat(existingSev.percentual) + parseFloat(curr.percentual)).toFixed(2);
        existingSev.id_nce.push(curr.id_nce); // Adiciona o id_nce na lista
      } else {
        // Caso contrário, adiciona o novo item ao acumulador
        acc.push({
          severidade: curr.severidade,
          qtd_defeitos: curr.qtd_defeitos,
          percentual: curr.percentual,
          id_nce: [curr.id_nce], // Coloca o id_nce em uma lista
        });
      }
      return acc;
    }, []);
    
    // em ordem de % percentual
    {FornServData2
      .sort((a: any, b: any) => parseFloat(b.percentual) - parseFloat(a.percentual)) // Ordenação numérica
      .map((item: any) => (
        <option value={item.severidade} key={item.severidade}>
          {item.percentual}%
        </option>
      ))
    }
    
    setTableGraphic3(FornServData2)

    // Separando labels e datasets
    const labels = Object.keys(FornServData);
    const quantidadeCritico = labels.map(id => FornServData[id].CRITICO);
    const quantidadeGrave = labels.map(id => FornServData[id].GRAVE);
    const quantidadeToleravel = labels.map(id => FornServData[id].TOLERAVEL);
    
    // Configuração do gráfico
    setDataSeveridadeDefeitos({
      labels,
      datasets: [
        {
          type: 'bar',
          label: 'Crítico',
          backgroundColor: 'rgba(255, 99, 132, 0.6)',
          borderColor: 'rgba(255, 99, 132, 0.8)',
          data: quantidadeCritico,
        },
        {
          type: 'bar',
          label: 'Grave',
          backgroundColor: 'rgba(54, 162, 235, 0.6)',
          borderColor: 'rgba(46, 114, 160, 0.8)',
          data: quantidadeGrave,
        },
        {
          type: 'bar',
          label: 'Tolerável',
          backgroundColor: 'rgba(75, 192, 192, 0.6)',
          borderColor: 'rgba(75, 192, 192, 0.8)',
          data: quantidadeToleravel,
        },
      ],
    });

    // ___________________________________________________________

    // Pareto de Fornecedores por Origem dos Defeitos
    const FornOrigemData: Record<string, { [key: string]: number }> = {};

    // Agrupando os dados por fornecedor e origem
    defectsOrigin.forEach(({ fornecedor, origem, qtd_defeitos }) => {
      if (!FornOrigemData[fornecedor]) {
        FornOrigemData[fornecedor] = {};
      }
      if (!FornOrigemData[fornecedor][origem]) {
        FornOrigemData[fornecedor][origem] = 0;
      }
      FornOrigemData[fornecedor][origem] += parseInt(qtd_defeitos);
    });

    const FornOrigemData2 = defectsOrigin.reduce((acc: any, curr: any) => {
      // Verifica se já existe um grupo para esse fornecedor e essa origem
      const existingGroup = acc.find(
          (item: { fornecedor: string; origem: string }) =>
              item.fornecedor === curr.fornecedor && item.origem === curr.origem
      );
   
      if (existingGroup) {
          existingGroup.qtd_defeitos = (parseInt(existingGroup.qtd_defeitos) + parseInt(curr.qtd_defeitos)).toString();
          existingGroup.percentual = (parseFloat(existingGroup.percentual) + parseFloat(curr.percentual)).toFixed(2);
          existingGroup.id_nce.push(curr.id_nce);
      } else {
          acc.push({
              fornecedor: curr.fornecedor,
              origem: curr.origem, 
              qtd_defeitos: curr.qtd_defeitos,
              percentual: curr.percentual,
              id_nce: [curr.id_nce],
          });
      }
   
      return acc;
  }, []);
    
    // Ordenação por percentual
    setTableGraphic4(
      FornOrigemData2.sort((a: any, b: any) => parseFloat(b.percentual) - parseFloat(a.percentual))
    );

    // Separando labels e datasets para o gráfico
    const labelsOrigem = Object.keys(FornOrigemData);
    const quantidadeProcesso = labelsOrigem.map(
      (fornecedor) => FornOrigemData[fornecedor]?.PROCESSO || 0
    );

    const quantidadeRecebimento = labelsOrigem.map(
      (fornecedor) => FornOrigemData[fornecedor]?.RECEBIMENTO || 0
    );
    // const dataOrigem = labelsOrigem.map((id) =>
    //   Object.values(FornOrigemData[id]).reduce((acc, val) => acc + val, 0)
    // );
    
    // Configuração do gráfico
    setDataOriginDefects({
      labels: labelsOrigem,
      datasets: [
        {
          type: 'bar',
          label: 'Processo',
          backgroundColor: 'rgba(154,205,50, 0.6)',
          borderColor: 'rgba(154,205,50, 0.8)',
          data: quantidadeProcesso,
        },
        {
          type: 'bar',
          label: 'Recebimento',
          backgroundColor: 'rgba(255, 159, 64, 0.6)',
          borderColor: 'rgba(255, 159, 64, 0.8)',
          data: quantidadeRecebimento,
        },
      ],
    });

  }, [
    principaisDefeitos,
    principaisDefeitosOrigens,
    defeitosSeveridade,
    defectsOrigin,
    severidade,
    originDefect,
  ]);

  const todayDate = new Date();

  const today = todayDate.toLocaleDateString();

  return (
    <div style={{ width: "100%" }}>
      <div className="header">
        <h1>Estratificação de defeitos</h1>
      </div>
      <div className="info-extract">
        <div style={{ display: "flex", width: "100%" }}>
          {/* ANO */}
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <label style={{ marginRight: 15, color: "#555555", width: "100%" }}>
              Ano
            </label>
            <select
              id="statusSelect"
              name=""
              className="select-defect"
              style={{ outline: "none" }}
              onChange={(e) => {
                if (e.target.value == "0") {
                  setDisabledButton(true);
                } else {
                  setYear(e.target.value);
                }
              }}
            >
              <option value="0">Selecione...</option>
              {anosPesquisa.map((item: any) => {
                return <option value={item}>{item}</option>;
              })}
            </select>
          </div>
          {/* FORNECEDOR */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <label style={{ marginRight: 15, color: "#555555", width: "100%" }}>
              Fornecedor
            </label>
            <select
              id="statusSelect"
              name=""
              className="select-defect"
              style={{ outline: "none" }}
              onChange={(e) => {
                if (e.target.value === "0") {
                  setDisabledButton(true);
                } else {
                  setObjectForn({ ...objectForn, idForn: e.target.value });
                  setDisabledButton(false);
                }
                setFornItem(e.target.value);
              }}
            >
              <option value="0">Selecione...</option>

              <option value="todos">Todos</option>

              {forn
                .sort((a: any, b: any) =>
                  a.fornecedorDescricao.localeCompare(b.fornecedorDescricao)
                )
                .map((item: any) => (
                  <option value={item.idFornecedor} key={item.idFornecedor}>
                    {item.fornecedorDescricao}
                  </option>
                ))}
            </select>
          </div>
          {/* PLANTA */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <label style={{ marginRight: 15, color: "#555555", width: "100%" }}>
              Planta
            </label>
            <select
              id="statusSelect"
              name=""
              className="select-defect"
              style={{ outline: "none" }}
              onChange={(e) => {
                if (e.target.value === "0") {
                  setDisabledButton(true);
                } else {
                  setDisabledButton(false);
                }
                setObjectForn({ ...objectForn, idUnidade: e.target.value });
              }}
            >
              <option value="0">Selecione...</option>

              <option value="todos">Todos</option>

              {FilterItemByName &&
                FilterItemByName.map((item: any) => (
                  <option
                    key={item.idFornecedorUnidade}
                    value={item.idFornecedorUnidade}
                  >
                    {item.fornecedorUnidadeDescricao}
                  </option>
                ))}
            </select>
          </div>
        </div>
        {/* PESQUISAR */}
        <div style={{ display: "flex" }}>
          <button
            className="button-defect-search "
            onClick={() => Search()}
            disabled={disabledButton}
          >
            <BsSearch style={{ marginRight: 10 }} />
            Pesquisar
          </button>
        </div>
      </div>
      {showGrafic && (
        <div
          style={{
            display: "flex",
            borderTop: "1px solid rgba(34,36,38,.15)",
            marginTop: 12,
          }}
        >
          <div className="content-left">
            {/* Pareto de Principais Defeitos */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <h1
                style={{ fontSize: 14, marginBottom: 15 }}
                className="title-graphic"
              >
                <GoGraph
                  color="rgba(163, 142, 23, 0.8)"
                  size={20}
                  style={{ marginRight: 20 }}
                />
                Pareto de Principais Defeitos
              </h1>
              <BarChat data={userData} options={options} />

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  marginTop: 5,
                }}
              >
                <h1 style={{ fontSize: 14 }} className="title-graphic">
                  <GoGraph
                    color="rgba(163, 142, 23, 0.8)"
                    size={20}
                    style={{ marginRight: 20 }}
                  />
                  NCEs por Defeitos
                  <button
                    onClick={toggleTableVisibility}
                    style={{
                      marginLeft: 20,
                      padding: "5px 10px",
                      backgroundColor: "#a38e17",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                  >
                    {isTableVisible ? "Ocultar Tabela" : "Ver detalhes"}
                  </button>
                </h1>

                {isTableVisible && (
                  <div className="ui container-table">
                    <table className="ui table">
                      <thead>
                        <tr style={{ textAlign: "center" }}>
                          <th>NCE</th>
                          <th>Percentual</th>
                          <th>Quantidade Defeitos</th>
                          <th>Tipo Defeito</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableGraphic?.map((listNce: any, index: number) => (
                          <tr key={index} style={{ textAlign: "center" }}>
                            <td>
                              {listNce.id_nce.map((id: string, idx: number) => (
                                <div key={idx}>{id}</div>
                              ))}
                            </td>
                            <td>{listNce.percentual.toFixed(2)}%</td>
                            <td>{listNce.qtd_defeitos}</td>
                            <td>{listNce.tipo_defeito}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
            <hr></hr>

            {/* Pareto de Fornecedores por Severidade de Defeito */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <h1 style={{ fontSize: 14 }} className="title-graphic">
                <GoGraph
                  color="rgba(163, 142, 23, 0.8)"
                  size={20}
                  style={{ marginRight: 20 }}
                />
                Pareto de Fornecedores por Severidade de Defeito
              </h1>
              <BarChat data={dataSeveridadeDefeitos} options={options} />

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  marginTop: 5,
                }}
              >
                <h1 style={{ fontSize: 14 }} className="title-graphic">
                  <GoGraph
                    color="rgba(205,92,92)"
                    size={20}
                    style={{ marginRight: 20 }}
                  />
                  Forn x NCEs por Severidade
                  <button
                    onClick={() => setIsTable3Visible(!isTable3Visible)}
                    style={{
                      marginLeft: 20,
                      padding: "5px 10px",
                      backgroundColor: "#CD5C5C",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                  >
                    {isTable3Visible ? "Ocultar Tabela" : "Ver Detalhes"}
                  </button>
                </h1>

                {/* Exibe a tabela somente se isTableVisible for true */}
                {isTable3Visible && (
                  <div className="ui container-table">
                    <table className="ui table">
                      <thead>
                        <tr style={{ textAlign: "center" }}>
                          <th>Severidade</th>
                          <th>NCE</th>
                          <th>Percentual</th>
                          <th>Quantidade Defeitos</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableGraphic3?.map((item: any, index: any) => (
                          <tr key={index} style={{ textAlign: "center" }}>
                            <td>{item.severidade}</td>
                            <td>
                              {item.id_nce.map((id: any, idx: any) => (
                                <div key={idx}>{id}</div>
                              ))}
                            </td>
                            <td>{item.percentual}%</td>
                            <td>{item.qtd_defeitos}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="content-right">
            {/* Pareto de Principais Origens de Defeitos */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <h1
                style={{ fontSize: 14, marginBottom: 15 }}
                className="title-graphic"
              >
                <GoGraph
                  color="rgba(163, 142, 23, 0.8)"
                  size={20}
                  style={{ marginRight: 20 }}
                />
                Pareto de Principais Origens de Defeitos
              </h1>

              <BarChat data={defeitosOrigem} options={options} />

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  marginTop: 5,
                }}
              >
                <h1 style={{ fontSize: 14 }} className="title-graphic">
                  <GoGraph
                    color="rgba(163, 142, 23, 0.8)"
                    size={20}
                    style={{ marginRight: 20 }}
                  />
                  NCEs por Origens
                  <button
                    onClick={() => setIsTable2Visible(!isTable2Visible)}
                    style={{
                      marginLeft: 20,
                      padding: "5px 10px",
                      backgroundColor: "#a38e17",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                  >
                    {isTable2Visible ? "Ocultar Tabela" : "Ver Detalhes"}
                  </button>
                </h1>

                {/* Exibe a tabela somente se isTableVisible for true */}
                {isTable2Visible && (
                  <div className="ui container-table">
                    <table className="ui table">
                      <thead>
                        <tr style={{ textAlign: "center" }}>
                          <th>Origem</th>
                          <th>NCE</th>
                          <th>Percentual</th>
                          <th>Quantidade Defeitos</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableGraphic2?.map((item: any, index: any) => (
                          <tr key={index} style={{ textAlign: "center" }}>
                            <td>{item.origem}</td>
                            <td>
                              {item.id_nce.map((id: any, idx: any) => (
                                <div key={idx}>{id}</div>
                              ))}
                            </td>
                            <td>{item.percentual}%</td>
                            <td>{item.qtd_defeitos}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>

            <hr></hr>

            {/* Pareto de Fornecedores por Origem de Defeito */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <h1 style={{ fontSize: 14 }} className="title-graphic">
                <GoGraph
                  color="rgba(163, 142, 23, 0.8)"
                  size={20}
                  style={{ marginRight: 20 }}
                />
                Pareto de Fornecedores por Origem de Defeito
              </h1>
              <BarChat data={dataOriginDefects} options={options} />

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  marginTop: 5,
                }}
              >
                <h1 style={{ fontSize: 14 }} className="title-graphic">
                  <GoGraph
                    color="rgba(255, 159, 64, 0.8)"
                    size={20}
                    style={{ marginRight: 20 }}
                  />
                  Forn x NCE por Origem
                  <button
                    onClick={() =>
                      setIsTable4Visible(!isTable4Visible)
                    }
                    style={{
                      marginLeft: 20,
                      padding: "5px 10px",
                      backgroundColor: "#ff9f40",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                  >
                    {isTable4Visible ? "Ocultar Tabela" : "Ver Detalhes"}
                  </button>
                </h1>

                {isTable4Visible && (
                  <div className="ui container-table">
                    <table className="ui table">
                      <thead>
                        <tr style={{ textAlign: "center" }}>
                          <th>Fornecedor</th>
                          <th>NCE</th>
                          <th>Origem</th>
                          <th>Percentual</th>
                          <th>Quantidade Defeitos</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableGraphic4?.map((item: any, index: any) => (
                          <tr key={index} style={{ textAlign: "center" }}>
                            <td>{item.fornecedor}</td>
                            <td>
                              {item.id_nce.map((id: any, idx: any) => (
                                <div key={idx}>{id}</div>
                              ))}
                            </td>
                            <td>{item.origem} </td>
                            <td>{item.percentual}%</td>
                            <td>{item.qtd_defeitos}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>

            </div>
          </div>
        </div>
      )}
      {showGrafic && (
        <div>
          <h2
            style={{
              fontSize: 14,
              fontWeight: 400,
              display: "flex",
              alignItems: "center",
            }}
          >
            {" "}
            <AiFillInfoCircle
              color="rgba(163, 142, 23, 0.8)"
              size={20}
              style={{ marginRight: 6 }}
            />{" "}
            Última atualização: {today}
          </h2>
        </div>
      )}
    </div>
  );
};

export default ErrorsNce;
