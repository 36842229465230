import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";

import "./itens.css";

import { IoMdRemoveCircleOutline } from "react-icons/io";

import {
  GetSuppliers,
  GetMaterials,
  GetGpUnidade,
  GetUnidade,
  GetProduto,
  GetUnidadeFornNCE,
  GetUsers,
  GetEmbalagem,
  GetNce,
  GetLastNce,
  GetEmbalagemFornNCE,
  GetProdutoFornNCE,
  GetDefectsFornNCE,
  GetAcessos,
  PostImgAws,
  GetEventos,
  CreateNceForm,
  UpdateCreateNceForm,
  GetNceDiaUtils,
} from "../../../data/services";

import { GetDefects } from "../../../data/services";
import secureLocalStorage from "react-secure-storage";
import { log } from "console";
import { NceModel } from "./NceModel";

import { useAuth } from "../../../context/auth";
import DivError from "../../../components/DivError";
import Axios from "../../../data/api";

type dadosPetropolis = {
  //dados do produto
  id: null;
  idNce: string;
  dataAbertura: string;
  responsavel: string;
  gpUnidade: string;
  status: string;
  embalagem: string;
  fornecedor: string;
  forUnidade: string;
  descricaoProduto: string;
  numeroDoLote: string;
  quantidadeDoLote: string;
  quantidadeDeDefeitos: string;
  quantidadeSegregada: string;
  parada: string;
  origemNc: string;
  severidade: string;
  sysDate: string;
  sysUsuario: string;
  sysDelete: string;
  sysDeleteUsuario: string;
  sysDeleteDate: string;
  pkTable: 0;
  dataDevolucaoNce: string;
  dataDevolucaoPrevistaNce: string;
  deleted: 0;

  //descricaoConformidadeData - /descricao-nce-ae
  tipoDeDefeito: string;
  nqa: string;
  descricaoDoDefeito: string;
  imagem: string;
  plano: string;
  inspecao: string;

  // /disposicao-do-produto
  disposicao: boolean;
  restricao: boolean;
  sucatear: boolean;
  liberar: boolean;
  retrabalhar: boolean;
  devolucao: boolean;
  estado: boolean;

  // /rep-list-nc
  idFornecedorUnidade: string;
  demerito: number;
  diasUteis: number;

  // /nce
  nceData: Date;
  idGpUnidade: number;
  idRec: number;

  // /acoes-imediatas-de-contencao
  acaoContencao: string;
  responsavelPelaAcao: string;
  dataAcao: string;
  statusAcao: string;

  // /brainstorming
  ideia: string;

  // /causa-e-efeito
  ideiaCausa: string;
  ideiaCategoria: string;

  //cincopqs - /análise de causa e efeito
  causaProvavel: string;
  porqueUm: string;
  porqueDois: string;
  porqueTres: string;
  porqueQuatro: string;
  porqueCinco: string;

  // /plano-de-acao
  causaOcorrencia: string;
  acaoTomada: string;
  acaoTipo: string;
  acaoResponsavel: string;
  acaoData: string;
  acaoStatus: string;

  // /verificacao-de-eficiencia
  concluidoPor: string;
  areaEmit: string;
  date: string;
  abrangente: string;
  eficaz: string;
  acao_eficaz: string;
  abrangencia: string;
  numeroDoPlanoDeAcao: string;
  user: string;
  dataVerificacao: string;
  comentario: string;

  // /acessos
  idUsuario: string;

  // /acessos_workflow
  idAcesso: number;
  sequencia: number;

  // /workflow_eventos
  etapaDescricao: string;
  finalizado: string;
  etapaData: string;
  etapaUsuario: string;

  idEvento: number;
  tipo: string;
  gpUnidadeDescricao: string;
};

const MultiStepForm = (item: any) => {
  const token = secureLocalStorage.getItem("access_token");
  let profile = secureLocalStorage.getItem("profile");

  const login = secureLocalStorage.getItem("login");
  let nomeUsuario = secureLocalStorage.getItem("nomeUsuario");
  const idUsuario = secureLocalStorage.getItem("idUsuario");
  const tipo = secureLocalStorage.getItem("tipo");
  const idFabrica = secureLocalStorage.getItem("idFabrica");

  const { user } = useAuth();

  if (!token) {
    window.location.href = "/";
  }

  const portal_url = "https://0h4sssk2fb.execute-api.us-east-1.amazonaws.com/prod";
  // const portal_url = "http://localhost:3001";
  // const portal_url = https://0h4sssk2fb.execute-api.us-east-1.amazonaws.com/prod;
  const portal_token = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<dadosPetropolis>({ criteriaMode: "all", mode: "all" });

  const [step, setStep] = useState(1);

  const User: any = secureLocalStorage.getItem("login") || "";

  const [fornecedor, setFornecedor] = React.useState<any>();
  const [idFornecedor, setIdFornecedor] = React.useState<any>(0);
  const [material, setMaterial] = React.useState<any>([]);
  const [idEmbalagem, setIdEmbalagem] = React.useState<any>("");
  const [idCategoriaEmbalagem, setIdCategoriaEmbalagem] =
    React.useState<any>("");
  const [embalagem, setEmbalagem] = React.useState<any>([]);
  const [unidadeforn, setUnidadeForn] = React.useState<any>([]);
  const [produto, setProduto] = React.useState<any>([]);

  const [valueNameFornecedor, setvalueNameFornecedor] = useState(
    item?.item?.dadosProduto?.FORNECEDOR
  );

  const [valueNameEmbalagem, setvalueNameEmbalagem] = useState(
    item?.item?.dadosProduto?.EMBALAGEM
  );

  const [valueIdUnidadeForn, setvalueIdUnidadeForn] = useState(
    item?.item?.dadosProduto?.FOR_UNIDADE
  );

  const [origemNc, setOrigemNc] = useState(item?.item?.dadosProduto?.ORIGEM_NC);
  const [abrangencia, setAbrangencia] = useState(
    item?.item?.verificacaoEficacia?.ABRANGENTE
  );
  const [acao_eficaz, setAcaoEficaz] = useState(
    item?.item?.verificacaoEficacia?.EFICAZ
  );
  const [severidade, setSeveridade] = useState(
    item?.item?.dadosProduto?.SEVERIDADE
  );
  const [disposicao, setDisposicao] = useState(
    item?.item?.disposicaoProduto?.DISPOSICAO
  );

  const [option, setOption] = React.useState<any>([]);
  const [idrec, setIdRec] = React.useState<any>([]);
  const [idRecLast, setIdRecLast] = React.useState<any>([]);
  const [nfrAcesso, setNfrAcesso] = React.useState<any>([]);
  const [idEvento, setIdEvento] = React.useState<any>([]);

  // const [nfrEvento, setNfrEvento] = React.useState<any>([]);
  const [usuario, setUsuarios] = React.useState<any>([]);
  const [nceModel, setNecModel] = React.useState<NceModel>();

  const [gpUnidade, setgpUnidade] = React.useState<any>([]);

  const [diasUteis, setDiasUteis] = React.useState<any>([]);

  const NCE = item.item;

  const ID_EVENTO = idEvento.map((item: any) => {
    return item.lastId;
  });

  const nextIdEvento = parseInt(ID_EVENTO) + 1;

  const stage = NCE?.WORKFLOW?.etapa_descricao;

  const handleSelectChangeFornecedor = (e: any) => {
    setIdFornecedor(e.target.value);
    const selectedOption = e.target.options[e.target.selectedIndex];
    const valueNameFornecedor = selectedOption.getAttribute(
      "data-valueNameFornecedor"
    );
    setvalueNameFornecedor(valueNameFornecedor);
  };
  const handleSelectChangeEmbalagem = (e: any) => {
    setIdEmbalagem(e.target.value);
    const selectedOption = e.target.options[e.target.selectedIndex];
    const valueNameEmbalagem = selectedOption.getAttribute(
      "data-valueNameEmbalagem"
    );
    setvalueNameEmbalagem(valueNameEmbalagem);
  };

  const handleSelectChangeUnidadeForn = (e: any) => {
    // setUnidadeForn(e.target.value)
    const selectedOption = e.target.options[e.target.selectedIndex];
    const valueIdUnidadeForn = selectedOption.getAttribute(
      "data-valueIdUnidadeForn"
    );
    setvalueIdUnidadeForn(valueIdUnidadeForn);
  };

  //descricaoConformidadeData - /descricao-nce-ae
  const [dataDescricaoDoDefeito, setDataDescricaoDoDefeito] = React.useState([
    {
      tipoDeDefeito: "",
      nqa: "",
      descricaoDoDefeito: "",
      imagem: "",
      plano: "",
      inspecao: "",
    },
  ]);

  const [file, setFile] = useState<any>(item?.item?.imagens || []);
  const [file2, setFile2] = useState<any>([]);

  const [returnBackImg, setReturnBackImg] = useState<any>(
    item?.item?.imagens || []
  );
  const [imagens, setImagens] = useState<any>([]);
  const urlImg: any = [];
  useMemo(() => {
    if (returnBackImg.length > 0) {
      // caso tenha imagens retornadas do banco, retorne um array de url dessas imgs
      returnBackImg.map((i: any) => urlImg.push(i.IMAGEM));
    }

    if (urlImg.length > 0) {
      let arrTemp: any[] = [];
      urlImg.map((i: any, index: any) => {
        arrTemp.push(i);
      });
      setImagens(arrTemp);
    }
  }, [returnBackImg]);

  const [imgToSaveMicroService, setImgToSaveMicroService] = useState<any>([]);
  // quando o onchange for acionado, add nesse array as novas imagens (base64).
  // Quando clicar no upload, percorrer esse array e enviar pro microserviço, pegar o retorno e salvar no banco como uma nova imagem.

  const convertToBase64 = (file: any) =>
    new Promise<any>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const uploadSingleFile = async (e: any) => {
    const convertedFile = await convertToBase64(e.target.files[0]);

    let date = new Date().toLocaleString().replace(/[/:, "]/g, "");

    let obj = {
      fileName: `${e.target.files[0].name}-${date}-${unidade}${dia}${idNewNce}`,
      file: convertedFile.replace(/data:[^;]+;base64,/, ""),
    };
    setImgToSaveMicroService([...imgToSaveMicroService, obj]);

    const url = URL.createObjectURL(e.target.files[0]);
    setImagens([...imagens, url]);
  };

  async function upload(e: any) {
    e.preventDefault();

    // if (imgToSaveMicroService.length > 0) {
    //   for (const defaultElement of imgToSaveMicroService) {
    //     if (defaultElement.includes('data:image')) {
    //       console.log('essa imagem contém formato base64')
    //     }
    //   }
    // }

    // if (file.length == 0) {
    //   toast.warning("Nenhum arquivo selecionado!", {
    //     position: toast.POSITION.TOP_CENTER,
    //   });
    // }
    // else {

    // if(JSON.stringify(imagens) !== JSON.stringify(returnBackImg)){
    // não houve alteração no array de imagens
    // console.log("não houve alteração no array de imagens");
    // }

    let itensRemovidos = returnBackImg.filter(
      (a: any) => !imagens.includes(a.IMAGEM)
    );
    // fazer o put no banco para atualizar o deleted p X
    itensRemovidos.length > 0 && console.log("itensRemovidos", itensRemovidos);

    for (const i of imgToSaveMicroService) {
      const urlRetorno = await PostImgAws(i.fileName, i.file);

      axios.post(
        `${portal_url}/imagens-nce-ae`,
        {
          id: 0,
          idNce: NCE?.ID_NCE ?? idNce,
          imagem: urlRetorno,
          sysDate: new Date(),
          sysUsuario: User,
          sysDelete: null,
          sysDeleteDate: null,
          sysDeleteUsuario: null,
          pkTable: "",
          deleted: 0,
        },
        portal_token
      );
    }
    // if(imagens.length > 0){
    //   for (const img of imagens) {
    //     if(img.includes('blob:')){
    //       const convertedFile = await convertToBase64(img)
    //     }
    //   }
    // }

    toast.info("Upload realizado com sucesso!", {
      position: toast.POSITION.TOP_CENTER,
    });
    // setTimeout(() => {
    //   window.location.reload();
    // }, 1000);
  }

  function deleteFile(e: any) {
    console.log(NCE?.imagens?.IMAGEM);
    const newImagens = imagens.filter((item: any, index: any) => index !== e);
    // ao cadastrar nce
    if (returnBackImg.length == 0) {
      const newImg = imgToSaveMicroService.filter(
        (item: any, index: any) => index !== e
      );
      setImgToSaveMicroService(newImg);
    }
    setImagens(newImagens);
  }

  var today = new Date().toLocaleDateString();

  const x = document.getElementById("today");

  var date = new Date();

  var numberOfDaysToAdd = 10;
  var soma_data_devolucao = date.setDate(date.getDate() + numberOfDaysToAdd);

  let unidade = "";
  let gpUnidadeDescricao = "";

  if (idFabrica === "1") {
    gpUnidadeDescricao = "BOITUVA";
    unidade = "BTV";
  }
  if (idFabrica === "2") {
    gpUnidadeDescricao = "PETRÓPOLIS";
    unidade = "PTR";
  }
  if (idFabrica === "3") {
    gpUnidadeDescricao = "TERESÓPOLIS";
    unidade = "TRS";
  }
  if (idFabrica === "4") {
    gpUnidadeDescricao = "RONDONÓPOLIS";
    unidade = "RDN";
  }
  if (idFabrica === "5") {
    gpUnidadeDescricao = "ALAGOINHAS";
    unidade = "ALG";
  }
  if (idFabrica === "6") {
    gpUnidadeDescricao = "ITAPISSUMA";
    unidade = "ITP";
  }
  if (idFabrica === "7") {
    gpUnidadeDescricao = "BRAGANÇA PAULISTA";
    unidade = "BRP";
  }
  if (idFabrica === "8") {
    gpUnidadeDescricao = "UBERABA";
    unidade = "UBR";
  }
  if (idFabrica === "9") {
    gpUnidadeDescricao = "CORPORATIVO";
    unidade = "CRP";
  }

  const currentDate = new Date();

  const year = currentDate.getFullYear().toString().slice(-2);
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");

  var dia = `${year}${month}`;

  // /disposicao-do-produto
  const [dataDisposicaoProduto, setDataDisposicaoProduto] = React.useState([
    {
      disposicao: "",
      restricao: "",
      sucatear: "",
      liberar: "",
      retrabalhar: "",
      devolucao: "",
      estado: "",
    },
  ]);

  // /acoes-imediatas-de-contencao
  const [dataAcoesContencao, setDataAcoesContencao] = React.useState([
    {
      acaoContencao: "",
      responsavelPelaAcao: "",
      dataAcao: "",
      statusAcao: "",
    },
  ]);

  function handleAddAcoesContencao() {
    setDataAcoesContencao((prevData) => [
      ...prevData,
      {
        acaoContencao: "",
        responsavelPelaAcao: "",
        dataAcao: "",
        statusAcao: "",
      },
    ]);
  }

  function handleRemoveAcoesContencao(index: any) {
    const newData = Array.from(dataAcoesContencao);

    newData.splice(index, 1);

    setDataAcoesContencao(newData);
  }

  // /brainstorming

  // const [dataIdeia, setDataIdeia] = React.useState<any>([]);
  const [dataIdeia, setDataIdeia] = React.useState<any>();

  const handleAddIdeia = (e: any) => {
    e.preventDefault();
    setDataIdeia([...dataIdeia, ""]);
  };

  // const handleChangeIdeia = (e: any, index: any) => {
  //   dataIdeia[index] = e.target.value;
  //   setDataIdeia([...dataIdeia]);
  // };
  const handleChangeIdeia = (e: any) => {
    setDataIdeia(e.target.value);
  };

  const handleDeleteIdeia = (position: any) => {
    setDataIdeia([
      ...dataIdeia.filter((_: any, index: any) => index != position),
    ]);
  };

  // /causa-e-efeito
  const [dataCausaEEfeito, setDataCausaEEfeito] = React.useState([
    {
      ideiaCausa: "",
      ideiaCategoria: "",
    },
  ]);

  function handleAddCausaEEfeito() {
    setDataCausaEEfeito([
      ...dataCausaEEfeito,
      {
        ideiaCausa: "",
        ideiaCategoria: "",
      },
    ]);
  }

  function handleRemoveCausaEEfeito(index: any) {
    const newData = Array.from(dataCausaEEfeito);

    newData.splice(index, 1);

    setDataCausaEEfeito(newData);
  }

  function updateDataCausaEEfeito(
    position: number,
    field: string,
    item: string
  ) {
    const updateDataItem = dataCausaEEfeito.map((standartItem, index) => {
      if (index === position) {
        return { ...standartItem, [field]: item };
      }

      return standartItem;
    });

    setDataCausaEEfeito(updateDataItem);
  }

  //cincopqs - /análise de causa e efeito
  const [dataCausaProvavel, setDataCausaProvavel] = React.useState([
    {
      causaProvavel: "",
      porqueUm: "",
      porqueDois: "",
      porqueTres: "",
      porqueQuatro: "",
      porqueCinco: "",
    },
  ]);

  function handleAddCausaProvavel() {
    setDataCausaProvavel([
      ...dataCausaProvavel,
      {
        causaProvavel: "",
        porqueUm: "",
        porqueDois: "",
        porqueTres: "",
        porqueQuatro: "",
        porqueCinco: "",
      },
    ]);
  }

  function handleRemoveCausaProvavel(index: any) {
    const newData = Array.from(dataCausaProvavel);

    newData.splice(index, 1);

    setDataCausaProvavel(newData);
  }

  function updateDataCausaProvavel(
    position: number,
    field: string,
    item: string
  ) {
    const updateDataItem = dataCausaProvavel.map((standartItem, index) => {
      if (index === position) {
        return { ...standartItem, [field]: item };
      }
      return standartItem;
    });

    setDataCausaProvavel(updateDataItem);
  }

  // /plano-de-acao
  const [dataPlanoDeAcao, setDataPlanoDeAcao] = React.useState([
    {
      causaOcorrencia: "",
      acaoTomada: "",
      acaoTipo: "",
      acaoResponsavel: "",
      acaoData: "",
      acaoStatus: "",
    },
  ]);

  function handleAddPlanoDeAcao() {
    setDataPlanoDeAcao([
      ...dataPlanoDeAcao,
      {
        causaOcorrencia: "",
        acaoTomada: "",
        acaoTipo: "",
        acaoResponsavel: "",
        acaoData: "",
        acaoStatus: "",
      },
    ]);
  }

  function handleRemovePlanoDeAcao(index: any) {
    const newData = Array.from(dataPlanoDeAcao);

    newData.splice(index, 1);

    setDataPlanoDeAcao(newData);
  }

  function updateDataPlanoDeAcao(
    position: number,
    field: string,
    item: string
  ) {
    const updateDataItem = dataPlanoDeAcao.map((standartItem, index) => {
      if (index === position) {
        return { ...standartItem, [field]: item };
      }

      return standartItem;
    });

    setDataPlanoDeAcao(updateDataItem);
  }

  const onSubmit = async (data: dadosPetropolis) => {
    try {
      setNecModel({
        ...nceModel,
        TIPO: "NCE",
        ID_NCE: "",
        NCE_DATA: new Date(),
        GP_UNIDADE_DESCRICAO: data.gpUnidade,
        LOGIN: secureLocalStorage.getItem("login")?.toString(),
        WORKFLOW: {
          id_evento: 0,
          id_nce: "",
          etapa_descricao: "ABERTURA",
          sequencia: 0,
          finalizado: "string",
          etapa_data: "string",
          comentario: "string",
          nome_usuario: secureLocalStorage.getItem("nomeUsuario")?.toString(),
        },
        dadosProduto: {
          ID: 0,
          ID_NCE: "string",
          DATA_ABERTURA: "string",
          RESPONSAVEL: "string",
          GP_UNIDADE: "string",
          STATUS: "string",
          EMBALAGEM: data.embalagem,
          FORNECEDOR: data.fornecedor,
          FOR_UNIDADE: data.forUnidade,
          DESCRICAO_PRODUTO: data.descricaoProduto,
          NUMERO_DO_LOTE: data.numeroDoLote,
          QUANTIDADE_DO_LOTE: data.quantidadeDoLote,
          QUANTIDADE_DE_DEFEITOS: data.quantidadeDeDefeitos,
          QUANTIDADE_SEGREGADA: data.quantidadeSegregada,
          PARADA: data.parada,
          ORIGEM_NC: data.origemNc,
          SEVERIDADE: data.severidade,
          SYS_DATE: "string",
          SYS_USUARIO: "string",
          SYS_DELETE: "string",
          SYS_DELETE_USUARIO: "string",
          SYS_DELETE_DATE: "string",
          PK_TABLE: 0,
          DATA_DEVOLUCAO_NCE: "string",
          DELETED: 0,
        },
        descricaoNCE: {
          ID: 0,
          ID_NCE: "string",
          TIPO_DE_DEFEITO: data.tipoDeDefeito,
          NQA: data.nqa,
          PLANO: data.plano,
          NIVEL_DE_INSPECAO: data.inspecao,
          DESCRICAO_DO_DEFEITO: data.descricaoDoDefeito,
          SYS_DATE: "string",
          SYS_USUARIO: "string",
          SYS_DELETE: "string",
          SYS_DELETE_USUARIO: "string",
          SYS_DELETE_DATE: "string",
          PK_TABLE: 0,
          DELETED: 0,
        },
        imagens: {
          ID: 0,
          ID_NCE: "string",
          IMAGEM: "string",
          SYS_DATE: "string",
          SYS_USUARIO: "string",
          SYS_DELETE: "string",
          SYS_DELETE_USUARIO: "string",
          SYS_DELETE_DATE: "string",
          PK_TABLE: 0,
          DELETED: 0,
        },
        disposicaoProduto: {
          ID: 0,
          ID_NCE: "string",
          DISPOSICAO: "string",
          SYS_DATE: "string",
          SYS_USUARIO: "string",
          SYS_DELETE: "string",
          SYS_DELETE_USUARIO: "string",
          SYS_DELETE_DATE: "string",
          PK_TABLE: 0,
          DELETED: 0,
        },
      });

      if (NCE?.ID_NCE) {
        const bodyUpdate = {
          // /dados-do-produto
          idNce: NCE?.ID_NCE ?? NCE?.dadosProduto?.ID_NCE,
          embalagem: data.embalagem ?? NCE?.dadosProduto?.EMBALAGEM,
          fornecedor: data.fornecedor ?? NCE?.dadosProduto?.FORNECEDOR,
          forUnidade: data.forUnidade ?? NCE?.dadosProduto?.FOR_UNIDADE,
          descricaoProduto:
            data.descricaoProduto ?? NCE?.dadosProduto?.DESCRICAO_PRODUTO,
          numeroDoLote: data.numeroDoLote ?? NCE?.dadosProduto?.NUMERO_DO_LOTE,
          quantidadeDoLote:
            data.quantidadeDoLote ?? NCE?.dadosProduto?.QUANTIDADE_DO_LOTE,
          quantidadeDeDefeitos:
            data.quantidadeDeDefeitos ??
            NCE?.dadosProduto?.QUANTIDADE_DE_DEFEITOS,
          quantidadeSegregada:
            data.quantidadeSegregada ?? NCE?.dadosProduto?.QUANTIDADE_SEGREGADA,
          parada: data.parada ?? NCE?.dadosProduto?.PARADA,
          origemNc: data.origemNc ?? NCE?.dadosProduto?.ORIGEM_NC,
          severidade: data.severidade ?? NCE?.dadosProduto?.SEVERIDADE,
          sysDate: new Date() ?? NCE?.dadosProduto?.SYS_DATE,
          sysUsuario: User ?? NCE?.dadosProduto?.SYS_USUARIO,

          //descricaoConformidadeData - /descricao-nce-ae
          tipoDeDefeito:
            data.tipoDeDefeito ?? NCE?.descricaoNCE?.TIPO_DE_DEFEITO,
          nqa: data.nqa ?? NCE?.descricaoNCE?.NQA,
          descricaoDoDefeito:
            data.descricaoDoDefeito ?? NCE?.descricaoNCE?.DESCRICAO_DO_DEFEITO,
          plano: data.plano ?? NCE?.descricaoNCE?.PLANO,
          nivelDeInspecao:
            data.inspecao ?? NCE?.descricaoNCE?.NIVEL_DE_INSPECAO,

          // /disposicao-do-produto
          disposicao: data.disposicao ?? NCE?.disposicaoProduto?.DISPOSICAO,
          restricao: data.restricao,
          sucatear: data.sucatear,
          liberar: data.liberar,
          retrabalhar: data.retrabalhar,
          devolucao: data.devolucao,
          estado: data.estado,

          // /acoes-imediatas-de-contencao
          acaoContencao:
            data.acaoContencao ?? NCE?.acoesImediatas?.ACAO_DE_CONTENCAO,
          responsavelPelaAcao:
            data.responsavelPelaAcao ?? NCE?.acoesImediatas?.QUEM,
          dataAcao: data.dataAcao ?? NCE?.acoesImediatas?.QUANDO,
          statusAcao: data.statusAcao ?? NCE?.acoesImediatas?.STATUS,

          // /brainstormig
          ideia: dataIdeia ?? NCE?.brainstorming?.IDEIA,

          // /causa-e-efeito
          ideiaCausa: data.ideiaCausa ?? NCE?.causaeefeito?.CAUSA_E_EFEITO,
          ideiaCategoria: data.ideiaCategoria ?? NCE?.causaeefeito?.M,

          //cincopqs - /análise de causa e efeito
          causaProvavel:
            data.causaProvavel ?? NCE?.cincoPorques?.CAUSA_PROVAVEL,
          porqueUm: data.porqueUm ?? NCE?.cincoPorques?.PORQUE_UM,
          porqueDois: data.porqueDois ?? NCE?.cincoPorques?.PORQUE_DOIS,
          porqueTres: data.porqueTres ?? NCE?.cincoPorques?.PORQUE_TRES,
          porqueQuatro: data.porqueQuatro ?? NCE?.cincoPorques?.PORQUE_QUATRO,
          porqueCinco: data.porqueCinco ?? NCE?.cincoPorques?.PORQUE_CINCO,

          // /plano-de-acao
          causaOcorrencia:
            data.causaOcorrencia ?? NCE?.planoAcao?.CAUSA_DA_OCORRENCIA,
          acaoTomada: data.acaoTomada ?? NCE?.planoAcao?.ACAO,
          acaoTipo: data.acaoTipo ?? NCE?.planoAcao?.TIPO,
          acaoResponsavel: data.acaoResponsavel ?? NCE?.planoAcao?.QUEM,
          acaoData: data.acaoData ?? NCE?.planoAcao?.QUANDO,
          acaoStatus: data.acaoStatus ?? NCE?.planoAcao?.STATUS,

          // /verificacao-de-eficiencia
          concluidoPor:
            user.tipo == "GP" && stage === "VERIFICACAO DE EFICACIA"
              ? User
              : "Nce incompleta",
          eficaz: data.eficaz ?? NCE?.verificacaoEficacia?.EFICAZ,
          acao_eficaz:
            data.acao_eficaz ?? NCE?.verificacaoEficacia?.ACAO_EFICAZ, //?
          abrangencia: data.abrangencia ?? NCE?.verificacaoEficacia?.ABRANGENTE,
          numeroDoPlanoDeAcao:
            data.numeroDoPlanoDeAcao ??
            NCE?.verificacaoEficacia?.NUMERO_DO_PLANO_DE_ACAO,
          areaVerificacao:
            data.areaEmit ?? NCE?.verificacaoEficacia?.AREA_EMIT,
          dataVerificacao:
            data.dataVerificacao ?? NCE?.verificacaoEficacia?.DATA,
          comentario: data.comentario ?? NCE?.verificacaoEficacia?.COMENTARIO,
        };
        await UpdateCreateNceForm(bodyUpdate);

        toast.success("Envio atualizado com sucesso!", {
          position: toast.POSITION.TOP_CENTER,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        const bodyCreate = {
          // /dados-do-produto
          id: null,
          idNce: `${unidade}${dia}${idNewNce}`,
          dataAbertura: new Date().toLocaleDateString(),
          responsavel: User,
          gpUnidade: gpUnidadeDescricao,
          status: "ABERTURA",
          embalagem: valueNameEmbalagem,
          fornecedor: valueNameFornecedor,
          forUnidade: data.forUnidade,
          descricaoProduto: data.descricaoProduto,
          numeroDoLote: data.numeroDoLote,
          quantidadeDoLote: data.quantidadeDoLote,
          quantidadeDeDefeitos: data.quantidadeDeDefeitos,
          quantidadeSegregada: data.quantidadeSegregada,
          parada: data.parada,
          origemNc: data.origemNc,
          severidade: data.severidade,
          sysDate: new Date(),
          sysUsuario: User,
          sysDelete: null,
          sysDeleteUsuario: null,
          sysDeleteDate: null,
          pkTable: "",
          dataDevolucaoNce: diasUteis,
          dataDevolucaoPrevistaNce: diasUteis,
          deleted: 0,

          //descricaoConformidadeData - /descricao-nce-ae
          tipoDeDefeito: data.tipoDeDefeito,
          nqa: data.nqa,
          descricaoDoDefeito: data.descricaoDoDefeito,
          imagem: data.imagem,
          plano: data.plano,
          inspecao: data.inspecao,

          // /disposicao-do-produto
          disposicao: data.disposicao,
          restricao: data.restricao,
          sucatear: data.sucatear,
          liberar: data.liberar,
          retrabalhar: data.retrabalhar,
          devolucao: data.devolucao,
          estado: data.estado,

          // /rep-list-nc
          idFornecedorUnidade: valueIdUnidadeForn,
          demerito: data.demerito,
          diasUteis: data.diasUteis,

          // /nce
          idGpUnidade: secureLocalStorage.getItem("idFabrica"),
          idRec: idNewNce,
          nceData: new Date(),

          // /acoes-imediatas-de-contencao
          acaoContencao: data.acaoContencao,
          responsavelPelaAcao: data.responsavelPelaAcao,
          dataAcao: data.dataAcao,
          statusAcao: data.statusAcao,

          // /brainstormig
          ideia: dataIdeia,

          // /causa-e-efeito
          ideiaCausa: data.ideiaCausa,
          ideiaCategoria: data.ideiaCategoria,

          //cincopqs - /análise de causa e efeito
          causaProvavel: data.causaProvavel,
          porqueUm: data.porqueUm,
          porqueDois: data.porqueDois,
          porqueTres: data.porqueTres,
          porqueQuatro: data.porqueQuatro,
          porqueCinco: data.porqueCinco,

          // /plano-de-acao
          causaOcorrencia: data.causaOcorrencia,
          acaoTomada: data.acaoTomada,
          acaoTipo: data.acaoTipo,
          acaoResponsavel: data.acaoResponsavel,
          acaoData: data.acaoData,
          acaoStatus: data.acaoStatus,

          // /verificacao-de-eficiencia
          concluidoPor: "Nce incompleta",
          date: data.date,
          abrangente: data.abrangente,
          eficaz: data.eficaz,
          acao_eficaz: data.acao_eficaz,
          abrangencia: data.abrangencia,
          numeroDoPlanoDeAcao: data.numeroDoPlanoDeAcao,
          // user: data.user,
          areaVerificacao: data.areaEmit,
          dataVerificacao: data.dataVerificacao,
          comentario: null,

          // /acessos
          idUsuario: secureLocalStorage.getItem("idUsuario")?.toString(),

          // /acessos_workflow
          etapaDescricao: "ABERTURA",
          sequencia: 1,
          idAcesso: nextIdAcesso,

          // /workflow_eventos
          finalizado: null,

          etapaData: null,
          etapaUsuario: null,
          idEvento: nextIdEvento,
          tipo: "NCE",
          gpUnidadeDescricao: gpUnidadeDescricao,
        };
        await CreateNceForm(bodyCreate);

        toast.success("Envio realizado com sucesso!", {
          position: toast.POSITION.TOP_CENTER,
        });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      if (error instanceof Error) {
        toast.error(`Erro ao enviar dados: ${error.message}`, {
          position: toast.POSITION.TOP_CENTER,
        });

        return error;
      }
    }
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  React.useEffect(() => {
    GetSuppliers(setFornecedor);
  }, []);

  React.useEffect(() => {
    !!idFornecedor && GetEmbalagemFornNCE(setEmbalagem, idFornecedor);
  }, [idFornecedor]);

  React.useEffect(() => {
    !!idFornecedor && GetUnidadeFornNCE(setUnidadeForn, idFornecedor);
  }, [idFornecedor]);

  React.useEffect(() => {
    !!idEmbalagem && GetProdutoFornNCE(setProduto, idEmbalagem);
  }, [idEmbalagem]);

  React.useEffect(() => {
    !!idEmbalagem && GetDefectsFornNCE(setOption, idEmbalagem);
  }, [idEmbalagem]);

  React.useEffect(() => {
    GetNce(setIdRec);
  }, []);

  React.useEffect(() => {
    GetLastNce(setIdRecLast);
  }, []);

  React.useEffect(() => {
    GetAcessos(setNfrAcesso);
  }, []);

  React.useEffect(() => {
    GetEventos(setIdEvento);
  }, []);

  // React.useEffect(() => {
  //   GetEventos(setNfrEvento);
  // }, []);

  React.useEffect(() => {
    GetUsers(setUsuarios);
  }, []);

  React.useEffect(() => {
    const data = new Date();
    // mandando um dia a mais pois o format parseiso retrocede um dia
    data.setDate(data.getDate() + 1);

    if (NCE?.NCE_DATA) {
      GetNceDiaUtils(setDiasUteis, NCE?.NCE_DATA, 10);
    } else {
      GetNceDiaUtils(setDiasUteis, data.toISOString().split("T")[0], 10);
    }
  }, []);

  const type = "defeito" ? "Defeitos" : "vazio";

  const ultimoAcesso = nfrAcesso.map((item: any) => {
    return item.lastId;
  });

  const nextIdAcesso = parseInt(ultimoAcesso) + 1;

  const idRecString = idRecLast?.[0]?.ID_REC;

  const idRec = parseInt(idRecString);

  const idNewNce = +idRec + 1;

  const idNce = `${unidade}${dia}${idNewNce}`;

  useEffect(() => {
    (async () => {
      // const filter = await fornecedor?.filter((itemF: any) => {
      //   return itemF?.fornecedorDescricao === NCE?.dadosProduto?.FORNECEDOR;
      // });

      // const filterUnitForn = await unidadeforn?.filter((itemF: any) => {
      //   return (
      //     itemF?.fornecedorUnidadeDescricao === NCE?.dadosProduto?.FOR_UNIDADE
      //   );
      // });

      // const filterEmbalagemForn = await embalagem?.filter((itemF: any) => {
      //   return itemF?.material.trim() === NCE?.dadosProduto?.EMBALAGEM.trim() && setIdEmbalagem(itemF.idEmbalagem);
      // });

      // const filterProduto = await produto?.filter((itemP: any) => {
      //   return itemP?.produto.trim() === NCE?.dadosProduto?.DESCRICAO_PRODUTO.trim();
      // })

      // if (filter?.length > 0) {
      // setIdFornecedor(filter[0]?.idFornecedor);
      // setValue("fornecedor", JSON.stringify(filter[0]?.idFornecedor));
      // setUnidadeForn(filterUnitForn);
      // setValue("forUnidade", filterUnitForn[0]?.fornecedorUnidadeDescricao);
      // setEmbalagem(filterEmbalagemForn);
      // setProduto(filterProduto)

      // setDataIdeia([
      //   {
      //     ideia: NCE?.brainstorming?.IDEIA ?? '',
      //   },
      // ]);
      setDataIdeia(NCE?.brainstorming?.IDEIA ?? "");
      setDataPlanoDeAcao([
        {
          acaoData: NCE?.planoAcao?.QUANDO,
          acaoResponsavel: NCE?.planoAcao?.QUEM,
          acaoStatus: NCE?.planoAcao?.STATUS,
          acaoTipo: NCE?.planoAcao?.TIPO,
          acaoTomada: NCE?.planoAcao?.ACAO,
          causaOcorrencia: NCE?.planoAcao?.CAUSA_DA_OCORRENCIA,
        },
      ]);

      // setValue("descricaoProduto", NCE?.dadosProduto?.DESCRICAO_PRODUTO)
      // setValue("tipoDeDefeito", NCE?.descricaoNCE?.TIPO_DE_DEFEITO)
      // setValue("embalagem", NCE?.dadosProduto?.EMBALAGEM);
      setValue("nqa", NCE?.descricaoNCE?.NQA);
      setValue("plano", NCE?.descricaoNCE?.PLANO);
      setValue("inspecao", NCE?.descricaoNCE?.NIVEL_DE_INSPECAO);
      setValue("descricaoDoDefeito", NCE?.descricaoNCE?.DESCRICAO_DO_DEFEITO);
      setValue("acaoContencao", NCE?.acoesImediatas?.ACAO_DE_CONTENCAO);
      setValue("responsavelPelaAcao", NCE?.acoesImediatas?.QUEM);
      setValue("dataAcao", NCE?.acoesImediatas?.QUANDO);
      setValue("statusAcao", NCE?.acoesImediatas?.STATUS);
      setValue("ideiaCausa", NCE?.causaeefeito?.CAUSA_E_EFEITO);
      setValue("ideiaCategoria", NCE?.causaeefeito?.M);
      setValue("causaProvavel", NCE?.cincoPorques?.CAUSA_PROVAVEL);
      setValue("porqueUm", NCE?.cincoPorques?.PORQUE_UM);
      setValue("porqueDois", NCE?.cincoPorques?.PORQUE_DOIS);
      setValue("porqueTres", NCE?.cincoPorques?.PORQUE_TRES);
      setValue("porqueQuatro", NCE?.cincoPorques?.PORQUE_QUATRO);
      setValue("porqueCinco", NCE?.cincoPorques?.PORQUE_CINCO);
      setValue("acaoData", NCE?.planoAcao?.QUANDO);
      setValue("acaoResponsavel", NCE?.planoAcao?.QUEM);
      setValue("acaoStatus", NCE?.planoAcao?.STATUS);
      setValue("acaoTipo", NCE?.planoAcao?.TIPO);
      setValue("acaoTomada", NCE?.planoAcao?.ACAO);
      setValue("causaOcorrencia", NCE?.planoAcao?.CAUSA_DA_OCORRENCIA);
      // }
    })();
  }, [fornecedor, item?.item?.dadosProduto?.FORNECEDOR, NCE]);

  function ordenar(a: any, b: any) {
    if (a.material < b.material) return -1;
    if (a.material > b.material) return 1;
    return 0;
  }

  !!embalagem && embalagem.sort(ordenar);

  const id = idFornecedor;

  const handleOrigemNcChange = (event: any) => {
    const newValue = event.target.value;
    setOrigemNc(newValue);
    setValue("origemNc", newValue);
  };

  const handleSeveridadeChange = (event: any) => {
    const newValue = event.target.value;
    setSeveridade(newValue);
    setValue("severidade", newValue);
  };

  const handleDisposicaoChange = (event: any) => {
    const newValue = event.target.value;
    setDisposicao(newValue);
    setValue("disposicao", newValue);
  };

  const handleAbrangenciaChange = (event: any) => {
    const newValue = event.target.value;
    setAbrangencia(newValue);
    setValue("abrangencia", newValue);
  };

  const handleAcaoEficazChange = (event: any) => {
    const newValue = event.target.value;
    setAcaoEficaz(newValue);
    setValue("acao_eficaz", newValue);
  };
  
  const handleComentarioChange = (event: any) => {
    const newValue = event.target.value;
    setAcaoEficaz(newValue);
    setValue("comentario", newValue);
  };
  const handleNumPlanAcaoChange = (event: any) => {
    const newValue = event.target.value;
    setAcaoEficaz(newValue);
    setValue("numeroDoPlanoDeAcao", newValue);
  };
  const handleAreaEmitChange = (event: any) => {
  const newValue = event.target.value;
    setAcaoEficaz(newValue);
    setValue("areaEmit", newValue);
  };
  const handleDataVerificacaoChange = (event: any) => {
    const newValue = event.target.value;
    setAcaoEficaz(newValue);
    setValue("dataVerificacao", newValue);
  };

  const isFieldDisabled = () => {
    if (
      step === 1 &&
      (profile === "usuarioColaborador" || profile === "superAdmin") &&
      (stage === "ABERTURA" || stage === undefined)
    ) {
      return false;
    } else if (
      step === 2 &&
      (profile === "usuarioColaborador" || profile === "superAdmin") &&
      (stage === "ABERTURA" || stage === undefined)
    ) {
      return true;
    } else if (
      step === 3 &&
      (profile === "usuarioColaborador" ||
        (profile === "superAdmin" &&
          stage === "VERIFICACAO DE EFICACIA" &&
          NCE?.WORKFLOW?.finalizado === null))
    ) {
      return false;
    } else if (
      step === 1 &&
      profile === "usuarioFornecedor" &&
      (stage === "ABERTURA" || stage === undefined)
    ) {
      return true;
    } else if (
      step === 2 &&
      profile === "usuarioFornecedor" &&
      stage === "PLANO DE ACAO"
    ) {
      return false;
    }
    return true;
  };

  const isFieldContencao = () => {
    if (
      step === 2 &&
      profile === "usuarioFornecedor" &&
      stage === "CONTENCAO"
    ) {
      return false;
    }
    return true;
  };

  const defaultvalue = "";

  const habilitaBotao = () => {
    if (user.tipo === "GP") {
      if ((stage === "ABERTURA" || stage === undefined) && step === 1) {
        // abrindo nce ou depois que salvou querere editar
        return true;
      }
      if (step === 2) {
        // área do forn
        return false;
      }
      if (
        stage === "VERIFICACAO DE EFICACIA" &&
        step === 3 &&
        NCE?.WORKFLOW?.finalizado === null
      ) {
        return true;
      }
      return false;
    } else if (user.tipo === "FORN") {
      if (step === 1) {
        // área do GP
        return false;
      }
      if (step === 2) {
        if (stage === "CONTENCAO" || stage === "PLANO DE ACAO") {
          return true;
        }
        return false;
      }
      if (step === 3) {
        // área do GP
        return false;
      }
    }
    return false;
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {step === 1 && (
        <fieldset>
          <div className="row">
            <p className="col-md-12 text-center">
              <legend> 1.0 DADOS DO PRODUTO - GRUPO PETRÓPOLIS</legend>
            </p>

            <div className="dadosProduto">
              <div className="row">
                <div className="col-md-6 ml-auto">
                  <label>
                    Número da NCE: {NCE?.ID_NCE ?? `${unidade}${dia}-xxxx`}
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="col-md-3 ml-auto">
                  <div className="form-group">
                    <label>Data de abertura:</label>
                    <p {...register("dataAbertura")}>
                      {NCE?.dadosProduto?.DATA_ABERTURA ?? today}
                    </p>
                  </div>
                </div>

                <div className="col-md-3 ml-auto">
                  <div className="form-group">
                    <label>Criado por:</label>
                    <p {...register("responsavel")}>
                      {NCE?.dadosProduto?.RESPONSAVEL ?? login}
                    </p>
                  </div>
                </div>

                <div className="col-md-3 ml-auto">
                  <div className="form-group">
                    <label>Unidade do Solicitante:</label>
                    <p {...register("gpUnidade")}>
                      {NCE?.dadosProduto?.GP_UNIDADE.trim() ??
                        gpUnidadeDescricao}
                    </p>
                  </div>
                </div>

                <div className="col-md-3 ml-auto">
                  <div className="form-group">
                    <label>Data de devolução prevista:</label>

                    <p {...register("dataDevolucaoPrevistaNce")}>{diasUteis}</p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 ml-auto">
                  <div className="form-group">
                    <label>Fornecedor:</label>

                    <select
                      defaultValue={id}
                      value={id}
                      disabled={isFieldDisabled()}
                      {...register("fornecedor", {
                        required: true,
                        validate: (value) => value != "",
                      })}
                      onChange={handleSelectChangeFornecedor}
                    >
                      {NCE?.dadosProduto?.FORNECEDOR ? (
                        <option>{NCE?.dadosProduto?.FORNECEDOR}</option>
                      ) : (
                        <>
                          <option value="">Selecione</option>
                          {fornecedor?.map((item: any, index: any) => {
                            return (
                              <option
                                key={index}
                                value={item.idFornecedor}
                                data-valueNameFornecedor={
                                  item.fornecedorDescricao
                                }
                              >
                                {item.fornecedorDescricao}
                              </option>
                            );
                          })}
                        </>
                      )}
                    </select>
                    {errors?.fornecedor && <DivError texto="Obrigatório!" />}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-3 ml-auto">
                  <div className="form-group">
                    <label>Unidade:</label>

                    <select
                      {...register("forUnidade", {
                        required: true,
                        validate: (value) => value != "",
                      })}
                      disabled={isFieldDisabled()}
                      onChange={handleSelectChangeUnidadeForn}
                    >
                      {!!NCE?.dadosProduto?.FOR_UNIDADE ? (
                        <option>{NCE?.dadosProduto?.FOR_UNIDADE}</option>
                      ) : (
                        <>
                          <option value="">Selecione</option>
                          {!!idFornecedor &&
                            unidadeforn?.map((item: any, index: any) => (
                              <option
                                key={index}
                                value={item.fornecedorUnidadeDescricao}
                                data-valueIdUnidadeForn={
                                  item.idFornecedorUnidade
                                }
                              >
                                {item.fornecedorUnidadeDescricao}
                              </option>
                            ))}
                        </>
                      )}
                    </select>
                    {errors?.forUnidade && <DivError texto="Obrigatório!" />}
                  </div>
                </div>

                <div className="col-md-3 ml-auto">
                  <div className="input-data">
                    <label>Embalagem:</label>

                    <select
                      {...register("embalagem", {
                        required: true,
                        validate: (value) => value != "",
                      })}
                      disabled={isFieldDisabled()}
                      onChange={handleSelectChangeEmbalagem}
                    >
                      {NCE?.dadosProduto?.EMBALAGEM ? (
                        <option>{NCE?.dadosProduto?.EMBALAGEM}</option>
                      ) : (
                        <>
                          <option value="">Selecione</option>
                          {!!idFornecedor &&
                            embalagem?.map((item: any, index: any) => {
                              return (
                                <option
                                  key={index}
                                  value={item.idEmbalagem}
                                  data-valueNameEmbalagem={item.material}
                                >
                                  {item.material}
                                </option>
                              );
                            })}
                        </>
                      )}
                    </select>
                    {errors?.embalagem && <DivError texto="Obrigatório!" />}
                  </div>
                </div>

                <div className="col-md-3 ml-auto">
                  <div className="input-data">
                    <label>Descrição do produto:</label>
                    <select
                      {...register("descricaoProduto", {
                        required: true,
                        validate: (value) => value != "",
                      })}
                      disabled={isFieldDisabled()}
                    >
                      {NCE?.dadosProduto?.DESCRICAO_PRODUTO ? (
                        <option>
                          {" "}
                          {NCE?.dadosProduto?.DESCRICAO_PRODUTO}{" "}
                        </option>
                      ) : (
                        <>
                          <option value="">Selecione</option>
                          {!!idEmbalagem &&
                            produto?.map((item: string, index: number) => {
                              return <option key={index}>{item}</option>;
                            })}
                        </>
                      )}
                    </select>
                    {errors?.descricaoProduto && (
                      <DivError texto="Obrigatório!" />
                    )}
                  </div>
                </div>

                <div className="col-md-3 ml-auto">
                  <div className="input-data">
                    <label>Parada:</label>
                    <input
                      type="time"
                      placeholder="Parada"
                      disabled={isFieldDisabled()}
                      {...register("parada")}
                      defaultValue={NCE?.dadosProduto?.PARADA.trim()}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-3 ml-auto">
                  <div className="form-group">
                    <label>Número do lote:</label>
                    <input
                      type="text"
                      placeholder="Número do Lote"
                      disabled={isFieldDisabled()}
                      defaultValue={NCE?.dadosProduto?.NUMERO_DO_LOTE.trim()}
                      {...register("numeroDoLote")}
                    />
                  </div>
                </div>

                <div className="col-md-3 ml-auto">
                  <div className="form-group">
                    <label>Quantidade do lote:</label>
                    <input
                      type="text"
                      placeholder="Quantidade do Lote"
                      {...register("quantidadeDoLote")}
                      disabled={isFieldDisabled()}
                      defaultValue={NCE?.dadosProduto?.QUANTIDADE_DO_LOTE.trim()}
                    />
                  </div>
                </div>

                <div className="col-md-3 ml-auto">
                  <div className="form-group">
                    <label>Quantidade de defeito:</label>
                    <input
                      type="text"
                      placeholder="Quantidade de defeito"
                      {...register("quantidadeDeDefeitos")}
                      disabled={isFieldDisabled()}
                      defaultValue={NCE?.dadosProduto?.QUANTIDADE_DE_DEFEITOS.trim()}
                    />
                  </div>
                </div>

                <div className="col-md-3 ml-auto">
                  <div className="form-group">
                    <label>Quantidade segregada:</label>
                    <input
                      type="text"
                      placeholder="Quantidade segregada"
                      {...register("quantidadeSegregada")}
                      disabled={isFieldDisabled()}
                      defaultValue={NCE?.dadosProduto?.QUANTIDADE_SEGREGADA.trim()}
                    />
                  </div>
                </div>
              </div>

              <div className="ckeckpoint">
                <div className="row">
                  <div className="col-md-12 ml-auto">
                    <label>Origem da NCE:</label>
                    <input
                      type="radio"
                      {...register("origemNc", {
                        required: "Você deve selecionar uma origem",
                      })}
                      name="origem"
                      value="RECEBIMENTO"
                      disabled={isFieldDisabled()}
                      data-modelo="ORIGEM_NC"
                      checked={origemNc === "RECEBIMENTO"}
                      onChange={handleOrigemNcChange}
                    />
                    <label className="form-check-label" id="recebimento">
                      Recebimento
                    </label>
                    <input
                      type="radio"
                      {...register("origemNc", {
                        required: "Você deve selecionar uma origem",
                      })}
                      name="origem"
                      value="PROCESSO"
                      data-modelo="ORIGEM_NC"
                      disabled={isFieldDisabled()}
                      checked={origemNc === "PROCESSO"}
                      onChange={handleOrigemNcChange}
                    />
                    <label className="form-check-label" id="processo">
                      Durante o processo
                    </label>
                    <input
                      type="radio"
                      {...register("origemNc", {
                        required: "Você deve selecionar uma origem",
                      })}
                      name="origem"
                      value="ESTOQUE"
                      disabled={isFieldDisabled()}
                      data-modelo="ORIGEM_NC"
                      checked={origemNc === "ESTOQUE"}
                      onChange={handleOrigemNcChange}
                    />
                    <label className="form-check-label" id="estoque">
                      Estoque
                    </label>
                    {errors?.origemNc && (
                      <DivError texto="Você deve selecionar uma origem!" />
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 ml-auto">
                    <label>Severidade:</label>

                    <input
                      type="radio"
                      {...register("severidade", {
                        required: "Você deve selecionar uma severidade",
                      })}
                      name="severidade"
                      value="CRITICO"
                      data-modelo="SEVERIDADE"
                      disabled={isFieldDisabled()}
                      checked={severidade === "CRITICO"}
                      onChange={handleSeveridadeChange}
                    />
                    <label className="form-check-label" id="critico">
                      Defeito Crítico
                    </label>
                    <input
                      type="radio"
                      {...register("severidade", {
                        required: "Você deve selecionar uma severidade",
                      })}
                      name="severidade"
                      value="GRAVE"
                      data-modelo="SEVERIDADE"
                      disabled={isFieldDisabled()}
                      checked={severidade === "GRAVE"}
                      onChange={handleSeveridadeChange}
                    />
                    <label className="form-check-label" id="grave">
                      Defeito Grave
                    </label>
                    <input
                      type="radio"
                      {...register("severidade", {
                        required: "Você deve selecionar uma severidade",
                      })}
                      name="severidade"
                      value="TOLERAVEL"
                      data-modelo="SEVERIDADE"
                      disabled={isFieldDisabled()}
                      checked={severidade === "TOLERAVEL"}
                      onChange={handleSeveridadeChange}
                    />
                    <label className="form-check-label" id="toleravel">
                      Defeito Tolerável
                    </label>
                    {errors?.severidade && (
                      <DivError texto="Você deve selecionar uma severidade!" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div className="row">
            <p className="col-md-12 text-center">
              <legend>
                {" "}
                2.0 DESCRIÇÃO DA NÃO CONFORMIDADE - GRUPO PETRÓPOLIS{" "}
              </legend>
            </p>
            <div className="descricaoConformidade">
              <div className="row">
                <div className="col-md-3 ml-auto">
                  <div className="input-data">
                    <label>Tipo de Defeito:</label>
                    <select
                      placeholder="Selecione o tipo de defeito"
                      {...register("tipoDeDefeito", {
                        required: true,
                        validate: (value) => value != "",
                      })}
                      disabled={isFieldDisabled()}
                    >
                      {NCE?.descricaoNCE?.TIPO_DE_DEFEITO ? (
                        <option> {NCE?.descricaoNCE?.TIPO_DE_DEFEITO} </option>
                      ) : (
                        <>
                          <option value="">Selecione</option>
                          {!!idEmbalagem &&
                            option?.map((item: any, index: any) => {
                              return (
                                <option key={index}>{item.defeito}</option>
                              );
                            })}
                        </>
                      )}
                    </select>
                    {errors?.tipoDeDefeito && <DivError texto="Obrigatório!" />}
                  </div>
                </div>

                <div className="col-md-3 ml-auto">
                  <div className="input-data">
                    <label>NQA:</label>
                    <select
                      defaultValue="0"
                      {...register("nqa", {
                        validate: (value) => value !== "0",
                      })}
                      disabled={isFieldDisabled()}
                    >
                      <option value="0">Selecione o NQA</option>
                      <option value="0,010">0,010</option>
                      <option value="0,015">0,015</option>
                      <option value="0,025">0,025</option>
                      <option value="0,040">0,040</option>
                      <option value="0,065">0,065</option>
                      <option value="0,10">0,10</option>
                      <option value="0,15">0,15</option>
                      <option value="0,25">0,25</option>
                      <option value="0,40">0,40</option>
                      <option value="0,65">0,65</option>
                      <option value="1,00">1,00</option>
                      <option value="1,50">1,50</option>
                      <option value="2,50">2,50</option>
                      <option value="4,00">4,00</option>
                      <option value="6,50">6,50</option>
                      <option value="10,00">10,00</option>
                    </select>
                    {errors.nqa && (
                      <p style={{ color: "red" }}>
                        *Por favor, selecione a nqa.
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-md-3 ml-auto">
                  <div className="input-data">
                    <label>Plano:</label>
                    <select
                      defaultValue="0"
                      {...register("plano", {
                        validate: (value) => value !== "0",
                      })}
                      disabled={isFieldDisabled()}
                    >
                      <option value="0">Tipo de plano</option>
                      <option value="Simples Normal">Simples Normal</option>
                      <option value="Simples Severa">Simples Severa</option>
                      <option value="Simples Atenuada">Simples Atenuada</option>
                    </select>
                    {errors.plano && (
                      <p style={{ color: "red" }}>
                        *Por favor, selecione o tipo de plano.
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-md-3 ml-auto">
                  <div className="input-data">
                    <label>Nível de Inspeção:</label>
                    <select
                      defaultValue="0"
                      {...register("inspecao", {
                        validate: (value) => value !== "0",
                      })}
                      disabled={isFieldDisabled()}
                    >
                      <option value="0">Nível de Inspeção</option>
                      <option value="Esp S1">Esp S1</option>
                      <option value="Esp S2">Esp S2</option>
                      <option value="Esp S3">Esp S3</option>
                      <option value="Esp S4">Esp S4</option>
                      <option value="Geral I">Geral I</option>
                      <option value="Geral II">Geral II</option>
                      <option value="Geral III">Geral III</option>
                    </select>
                    {errors.inspecao && (
                      <p style={{ color: "red" }}>
                        *Por favor, selecione o nível de inspeçãoo.
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 ml-auto">
                  <div className="input-data">
                    <label>Descrição do defeito:</label>

                    {dataDescricaoDoDefeito.map((item, index) => {
                      return (
                        <textarea
                          disabled={isFieldDisabled()}
                          placeholder="Descreva aqui o tipo de defeito."
                          className="col-md-12"
                          {...register("descricaoDoDefeito")}
                          key={item.descricaoDoDefeito}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 ml-auto">
                  <div className="input-data">
                    <label>Imagem:</label>

                    <form>
                      {/* <div className="form-group preview file-aline"> */}
                      <div className="form-group preview">
                        {imagens.length > 0 &&
                          imagens.map((itemFile: any, index: any) => {
                            return (
                              <div>
                                <div>
                                  <iframe
                                    src={itemFile}
                                    title={itemFile}
                                    className="responsive-iframe"
                                  />
                                </div>
                                <div>
                                  <button
                                    type="button"
                                    className="btn btn-light"
                                    onClick={() => deleteFile(index)}
                                  >
                                    <IoMdRemoveCircleOutline
                                      color="red"
                                      size={20}
                                      style={{ margin: 2 }}
                                    />
                                  </button>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                      {!(returnBackImg.length > 0) && (
                        <>
                          <div className="form-group">
                            <label
                              htmlFor="selecao-arquivo"
                              className="label-file"
                            >
                              Selecionar um arquivo &#187;
                            </label>
                            <input
                              //multiple
                              id="selecao-arquivo"
                              type="file"
                              className="form-control"
                              accept="image/*"
                              style={{ display: "none" }}
                              onChange={uploadSingleFile}
                              key={imagens || ""}
                            />
                          </div>

                          <button
                            type="button"
                            className="btn btn-primary btn-block"
                            onClick={upload}
                            {...register("imagem")}
                          >
                            Salvar Imagem
                          </button>
                        </>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div className="row">
            <p className="col-md-12 text-center">
              <legend> 3.0 DISPOSIÇÃO DO PRODUTO - GRUPO PETRÓPOLIS </legend>
            </p>
          </div>

          <div className="ckeckpoint">
            <div className="row">
              <div className="col-md-12 ml-auto">
                {dataDisposicaoProduto.map((item, index) => {
                  return (
                    <>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value="LIBERAR_COM_RESTRICAO"
                          id="restricao"
                          {...register("restricao")}
                          key={item.restricao}
                          checked={disposicao === "LIBERAR_COM_RESTRICAO"}
                          onChange={handleDisposicaoChange}
                          disabled={isFieldDisabled()}
                        />
                        <label
                          className="form-check-label"
                          id="restricao"
                          style={{ width: "25rem" }}
                        >
                          Liberar com restrição
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value="SUCATEAR_OU_DESTRUIR"
                          id="sucatear"
                          {...register("sucatear")}
                          key={item.sucatear}
                          checked={disposicao === "SUCATEAR_OU_DESTRUIR"}
                          onChange={handleDisposicaoChange}
                          disabled={isFieldDisabled()}
                        />
                        <label
                          className="form-check-label"
                          id="sucatear"
                          style={{ width: "25rem" }}
                        >
                          Sucatear ou Destruir
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value="LIBERAR_SOB_CONCESSAO"
                          id="liberar"
                          {...register("liberar")}
                          key={item.liberar}
                          checked={disposicao === "LIBERAR_SOB_CONCESSAO"}
                          onChange={handleDisposicaoChange}
                          disabled={isFieldDisabled()}
                        />
                        <label
                          className="form-check-label"
                          id="liberar"
                          style={{ width: "25rem" }}
                        >
                          Liberar sob Concessão
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value="RETRABALHAR"
                          id="retrabalhar"
                          {...register("retrabalhar")}
                          key={item.retrabalhar}
                          checked={disposicao === "RETRABALHAR"}
                          onChange={handleDisposicaoChange}
                          disabled={isFieldDisabled()}
                        />
                        <label
                          className="form-check-label"
                          id="retrabalhar"
                          style={{ width: "25rem" }}
                        >
                          Retrabalhar
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value="DEVOLUCAO"
                          id="devolucao"
                          {...register("devolucao")}
                          key={item.devolucao}
                          checked={disposicao === "DEVOLUCAO"}
                          onChange={handleDisposicaoChange}
                          disabled={isFieldDisabled()}
                        />
                        <label
                          className="form-check-label"
                          id="devolucao"
                          style={{ width: "25rem" }}
                        >
                          Devolução
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value="PROCESSO"
                          id="estado"
                          {...register("estado")}
                          key={item.estado}
                          checked={disposicao === "PROCESSO"}
                          onChange={handleDisposicaoChange}
                          disabled={isFieldDisabled()}
                        />
                        <label
                          className="form-check-label"
                          id="estado"
                          style={{ width: "25rem" }}
                        >
                          Utilizar no estado
                        </label>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 ml-auto">
              <button
                type="button"
                onClick={handleNextStep}
                className="btn btn-info"
                style={{ float: "right" }}
              >
                Próxima
              </button>
            </div>
          </div>

          {habilitaBotao() && (
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-success btn-block"
                style={{ float: "right" }}
              >
                Salvar
              </button>
              <ToastContainer />
            </div>
          )}
          <hr />
        </fieldset>
      )}
      {step === 2 && (
        <fieldset>
          <div className="row">
            <p className="col-md-12 text-center">
              <legend> 4.0 AÇÕES IMEDIATAS DE CONTENCAO - FORNECEDOR </legend>
            </p>

            <div className="acoesContencao">
              <div className="row">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Ações de Contenção do Defeito</th>
                        <th scope="col">Quem?</th>
                        <th scope="col">Quando?</th>
                        <th scope="col">Status</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>

                    {dataAcoesContencao.map((item, index) => {
                      return (
                        <tbody>
                          <tr>
                            <td>
                              <textarea
                                placeholder="Insira aqui a ação de contenção"
                                //@ts-ignore
                                // {...register(`acaoContencao.${index}`, { defaultvalue })}
                                {...register(`acaoContencao`)}
                                key={item.acaoContencao}
                                disabled={isFieldContencao()}
                                defaultValue={
                                  NCE?.acoesImediatas?.ACAO_DE_CONTENCAO
                                }
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                placeholder="Insira o responsável pela ação"
                                //@ts-ignore
                                // {...register(`responsavelPelaAcao.${index}`, { defaultvalue })}
                                {...register("responsavelPelaAcao")}
                                key={item.responsavelPelaAcao}
                                disabled={isFieldContencao()}
                                defaultValue={NCE?.acoesImediatas?.QUEM}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                placeholder="Insira quando a ação foi tomada"
                                //@ts-ignore
                                // {...register(`dataAcao.${index}`, { defaultvalue })}
                                {...register("dataAcao")}
                                key={item.dataAcao}
                                disabled={isFieldContencao()}
                                defaultValue={NCE?.acoesImediatas?.QUANDO}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                placeholder="Insira o status da ação"
                                //@ts-ignore
                                // {...register(`statusAcao.${index}`, { defaultvalue })}
                                {...register("statusAcao")}
                                key={item.statusAcao}
                                disabled={isFieldContencao()}
                                defaultValue={NCE?.acoesImediatas?.STATUS}
                              />
                            </td>

                            <button
                              type="button"
                              className="btn btn-light"
                              disabled={isFieldContencao()}
                              onClick={(e) => handleRemoveAcoesContencao(index)}
                            >
                              <IoMdRemoveCircleOutline
                                color="red"
                                size={20}
                                style={{ margin: 2 }}
                              />
                            </button>
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>
                </div>

                {/* <div className="row text-center">
                  <button
                    type="button"
                    className="btn btn-light"
                    disabled={isFieldDisabled()}
                    onClick={handleAddAcoesContencao}
                  >
                    Adicionar nova ação de contenção
                  </button>
                </div> */}
              </div>
              <hr />
            </div>
          </div>

          <div className="row">
            <p className="col-md-12 text-center">
              <legend> 5.0 BRAINSTORMING - FORNECEDOR </legend>
            </p>

            <div className="brainstorming">
              <div className="row">
                <label className="col-md-12 text-center">Ideias</label>

                {/* {dataIdeia.map((item: any, index: any) => {
                  return (
                    <div className="row">
                      <textarea
                        className="col-md-12"
                        placeholder={`Ideia ${index + 1}`}
                        id={`id ${index + 1}`}
                        disabled={isFieldDisabled()}
                        onChange={(e) => {
                          handleChangeIdeia(e, index);
                        }}
                        key={item.ideia}
                        value={item.ideia && item.ideia}
                        defaultValue={NCE?.brainstorming?.IDEIA}
                      ></textarea>

                      <button
                        type="button"
                        className="btn btn-light"
                        disabled={isFieldDisabled()}
                        onClick={(e) => handleDeleteIdeia(index)}
                      >
                        <IoMdRemoveCircleOutline
                          color="red"
                          size={20}
                          style={{ margin: 2 }}
                        />
                      </button>
                    </div>
                  );
                })} */}

                <div className="row">
                  <textarea
                    className="col-md-12"
                    placeholder={`Descreva sua ideia`}
                    disabled={isFieldDisabled()}
                    onChange={(e) => {
                      handleChangeIdeia(e);
                    }}
                    key={item.ideia}
                    value={item.ideia && item.ideia}
                    defaultValue={NCE?.brainstorming?.IDEIA}
                  ></textarea>
                </div>
              </div>

              {/* <div className="row text-center">
                <button
                  type="button"
                  className="btn btn-light"
                  disabled={isFieldDisabled()}
                  onClick={handleAddIdeia}
                >
                  Adicionar nova ideia
                </button>
              </div> */}

              <hr />
            </div>
          </div>

          <div className="row">
            <p className="col-md-12 text-center">
              <legend> 6.1 - ANÁLISE DE CAUSA E EFEITO - FORNECEDOR </legend>
            </p>

            <div className="causaEfeito">
              {dataCausaEEfeito.map((item, index) => {
                return (
                  <>
                    <div className="row">
                      <label className="col-md-12 text-center">Ideia</label>

                      <textarea
                        className="col-md-12"
                        placeholder="Insira aqui a ideia."
                        //@ts-ignore
                        // {...register(`ideiaCausa.${index}`, { defaultvalue })}
                        {...register("ideiaCausa")}
                        key={item.ideiaCausa}
                        disabled={isFieldDisabled()}
                        defaultValue={NCE?.causaeefeito?.CAUSA_E_EFEITO}
                      ></textarea>
                    </div>

                    <div className="row">
                      <label className="col-md-12 text-center">6M</label>

                      <textarea
                        className="col-md-12"
                        placeholder="Insira aqui a categoria 6M: máquina, materiais, mão de obra, meio ambiente, método e medidas."
                        disabled={isFieldDisabled()}
                        //@ts-ignore
                        {...register("ideiaCategoria")}
                        // {...register(`ideiaCategoria.${index}`, { defaultvalue })}
                        key={item.ideiaCategoria}
                        defaultValue={NCE?.causaeefeito?.M}
                      ></textarea>
                    </div>

                    <button
                      type="button"
                      disabled={isFieldDisabled()}
                      onClick={(e) => handleRemoveCausaEEfeito(index)}
                    >
                      <IoMdRemoveCircleOutline
                        color="red"
                        size={20}
                        style={{ margin: 2 }}
                      />
                    </button>
                  </>
                );
              })}
              {/* <div className="row text-center">
                <button
                  type="button"
                  className="btn btn-light"
                  disabled={isFieldDisabled()}
                  onClick={handleAddCausaEEfeito}
                >
                  Adicionar nova causa e efeito
                </button>
              </div> */}
              <hr />
            </div>
          </div>

          <div className="row">
            <p className="col-md-12 text-center">
              <legend>
                {" "}
                6.2 - CINCO POR QUÊS DAS CAUSAS PROVÁVEIS - FORNECEDOR{" "}
              </legend>
            </p>
            <div className="row">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Causas prováveis</th>
                      <th scope="col">1. Por quê?</th>
                      <th scope="col">2. Por quê?</th>
                      <th scope="col">3. Por quê?</th>
                      <th scope="col">4. Por quê?</th>
                      <th scope="col">5. Por quê?</th>
                    </tr>
                  </thead>

                  {dataCausaProvavel.map((item, index) => {
                    return (
                      <tbody key={index}>
                        <tr>
                          <td>
                            <textarea
                              placeholder="Insira aqui a causa provável"
                              //@ts-ignore
                              // {...register(`causaProvavel.${index}`, { defaultvalue })}
                              {...register("causaProvavel")}
                              disabled={isFieldDisabled()}
                              key={item.causaProvavel}
                              defaultValue={NCE?.cincoPorques?.CAUSA_PROVAVEL}
                            />
                          </td>
                          <td>
                            <textarea
                              placeholder="Insira aqui o 1° Por Que"
                              //@ts-ignore
                              // {...register(`porqueUm.${index}`, { defaultvalue })}
                              {...register("porqueUm")}
                              disabled={isFieldDisabled()}
                              key={item.porqueUm}
                              defaultValue={NCE?.cincoPorques?.PORQUE_UM}
                            />
                          </td>
                          <td>
                            <textarea
                              placeholder="Insira aqui o 2° Por Que"
                              //@ts-ignore
                              // {...register(`porqueDois.${index}`, { defaultvalue })}
                              {...register("porqueDois")}
                              disabled={isFieldDisabled()}
                              key={item.porqueDois}
                              defaultValue={NCE?.cincoPorques?.PORQUE_DOIS}
                            />
                          </td>
                          <td>
                            <textarea
                              placeholder="Insira aqui o 3° Por Que"
                              //@ts-ignore
                              // {...register(`porqueTres.${index}`, { defaultvalue })}
                              {...register("porqueTres")}
                              disabled={isFieldDisabled()}
                              key={item.porqueTres}
                              defaultValue={NCE?.cincoPorques?.PORQUE_TRES}
                            />
                          </td>
                          <td>
                            <textarea
                              placeholder="Insira aqui o 4° Por Que"
                              //@ts-ignore
                              // {...register(`porqueQuatro.${index}`, { defaultvalue })}
                              {...register("porqueQuatro")}
                              disabled={isFieldDisabled()}
                              key={item.porqueQuatro}
                              defaultValue={NCE?.cincoPorques?.PORQUE_QUATRO}
                            />
                          </td>
                          <td>
                            <textarea
                              placeholder="Insira aqui o 5° Por Que"
                              //@ts-ignore
                              // {...register(`porqueCinco.${index}`, { defaultvalue })}
                              {...register("porqueCinco")}
                              disabled={isFieldDisabled()}
                              key={item.porqueCinco}
                              defaultValue={NCE?.cincoPorques?.PORQUE_CINCO}
                            />
                          </td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-light"
                              disabled={isFieldDisabled()}
                              onClick={(e) => handleRemoveCausaProvavel(index)}
                            >
                              <IoMdRemoveCircleOutline
                                color="red"
                                size={20}
                                style={{ margin: 2 }}
                              />
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </div>
              {/* <div className="row text-center">
                <button
                  type="button"
                  className="btn btn-light"
                  disabled={isFieldDisabled()}
                  onClick={handleAddCausaProvavel}
                >
                  Adicionar nova causa provável
                </button>
              </div> */}
            </div>
          </div>

          <div className="row">
            <p className="col-md-12 text-center">
              <legend>
                {" "}
                7.0 PLANO DE AÇÃO CORRETIVA E PREVENTIVA - FORNECEDOR{" "}
              </legend>
            </p>

            <div className="planoAcao">
              <div className="row">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">Causa da Ocorrência</th>
                        <th scope="col">Ação</th>
                        <th scope="col">Tipo</th>
                        <th scope="col">Quem?</th>
                        <th scope="col">Quando?</th>
                        <th scope="col">Status</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>

                    {dataPlanoDeAcao.map((item, index) => {
                      return (
                        <tbody key={index}>
                          <tr>
                            <td>
                              <textarea
                                placeholder="Insira aqui a causa da ocorrência"
                                //@ts-ignore
                                // {...register(`causaOcorrencia.${index}`, { defaultvalue })}
                                {...register("causaOcorrencia")}
                                key={item.causaOcorrencia}
                                // onChange={(e) =>
                                //   updateDataPlanoDeAcao(
                                //     index,
                                //     "causaOcorrencia",
                                //     e.target.value
                                //   )
                                // }
                                disabled={isFieldDisabled()}
                                // value={dataPlanoDeAcao[index].causaOcorrencia}
                                defaultValue={
                                  NCE?.planoAcao?.CAUSA_DA_OCORRENCIA
                                }
                              />
                            </td>
                            <td>
                              <textarea
                                placeholder="Insira aqui a ação a ser tomada"
                                //@ts-ignore
                                // {...register(`acaoTomada.${index}`, { defaultvalue })}
                                {...register("acaoTomada")}
                                disabled={isFieldDisabled()}
                                key={item.acaoTomada}
                                defaultValue={NCE?.planoAcao?.ACAO}
                              />
                            </td>
                            <td>
                              <textarea
                                placeholder="Insira aqui o tipo da ação"
                                //@ts-ignore
                                // {...register(`acaoTipo.${index}`, { defaultvalue })}
                                {...register("acaoTipo")}
                                disabled={isFieldDisabled()}
                                key={item.acaoTipo}
                                defaultValue={NCE?.planoAcao?.TIPO}
                              />
                            </td>
                            <td>
                              <textarea
                                placeholder="Insira aqui quem será o responsável pela ação"
                                //@ts-ignore
                                // {...register(`acaoResponsavel.${index}`, { defaultvalue })}
                                {...register("acaoResponsavel")}
                                disabled={isFieldDisabled()}
                                key={item.acaoResponsavel}
                                defaultValue={NCE?.planoAcao?.QUEM}
                              />
                            </td>
                            <td>
                              <textarea
                                placeholder="Insira aqui quando a ação será tomada"
                                //@ts-ignore
                                // {...register(`acaoData.${index}`, { defaultvalue })}
                                {...register("acaoData")}
                                disabled={isFieldDisabled()}
                                key={item.acaoData}
                                defaultValue={NCE?.planoAcao?.QUANDO}
                              />
                            </td>
                            <td>
                              <textarea
                                placeholder="Insira aqui o status da ação"
                                //@ts-ignore
                                // {...register(`acaoStatus.${index}`, { defaultvalue })}
                                {...register("acaoStatus")}
                                disabled={isFieldDisabled()}
                                key={item.acaoStatus}
                                defaultValue={NCE?.planoAcao?.STATUS}
                              />
                            </td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-light"
                                disabled={isFieldDisabled()}
                                onClick={(e) => handleRemovePlanoDeAcao(index)}
                              >
                                <IoMdRemoveCircleOutline
                                  color="red"
                                  size={20}
                                  style={{ margin: 2 }}
                                />
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>
                </div>

                {/* <div className="row text-center">
                  <button
                    type="button"
                    className="btn btn-light"
                    disabled={isFieldDisabled()}
                    onClick={handleAddPlanoDeAcao}
                  >
                    Adicionar novo plano de ação
                  </button>
                </div> */}
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 ml-auto">
                <div className="row">
                  <div className="col-md-12 ml-auto">
                    <button
                      type="button"
                      style={{ float: "left" }}
                      className="btn btn-secondary"
                      onClick={handlePrevStep}
                    >
                      Anterior
                    </button>
                    <button
                      type="button"
                      onClick={handleNextStep}
                      className="btn btn-info"
                      style={{ float: "right" }}
                    >
                      Próxima
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {habilitaBotao() && (
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-success btn-block"
                style={{ float: "right" }}
              >
                Salvar
              </button>
              <ToastContainer />
            </div>
          )}
        </fieldset>
      )}
      {step === 3 && (
        <fieldset>
          <div className="row">
            <p className="col-md-12 text-center">
              <legend> 8.0 VERIFICAÇÃO DE EFICÁCIA - GRUPO PETRÓPOLIS </legend>
            </p>

            <div className="verificacaoEficiencia">
              <div className="ckeckpoint">
                <div className="row">
                  <div className="col-md-12 ml-auto">
                    <label>Abrangente:</label>
                    <input
                      type="radio"
                      {...register("abrangencia")}
                      name="abrangencia"
                      value="SIM"
                      disabled={isFieldDisabled()}
                      data-modelo="ABRANGENTE"
                      checked={abrangencia === "SIM"}
                      onChange={handleAbrangenciaChange}
                    />
                    <label className="form-check-label" id="simAbrangente">
                      Sim
                    </label>

                    <input
                      type="radio"
                      {...register("abrangencia")}
                      name="abrangencia"
                      value="NAO"
                      disabled={isFieldDisabled()}
                      data-modelo="ABRANGENTE"
                      checked={abrangencia === "NAO"}
                      onChange={handleAbrangenciaChange}
                    />
                    <label className="form-check-label" id="naoAbrangente">
                      Não
                    </label>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 ml-auto">
                    <label>Ação Eficaz:</label>

                    <input
                      type="radio"
                      {...register("acao_eficaz")}
                      name="acao_eficaz"
                      value="SIM"
                      disabled={isFieldDisabled()}
                      data-modelo="EFICAZ"
                      checked={acao_eficaz === "SIM"}
                      onChange={handleAcaoEficazChange}
                    />
                    <label className="form-check-label" id="acao_eficaz">
                      Sim
                    </label>

                    <input
                      type="radio"
                      {...register("acao_eficaz")}
                      name="acao_eficaz"
                      value="NAO"
                      disabled={isFieldDisabled()}
                      data-modelo="EFICAZ"
                      checked={acao_eficaz === "NAO"}
                      onChange={handleAcaoEficazChange}
                    />
                    <label className="form-check-label" id="acao_eficaz">
                      Não
                    </label>
                  </div>
                </div>
              </div>

              <textarea
                className="col-md-12 ml-auto"
                placeholder="Comentários adicionais"
                disabled={isFieldDisabled()}
                {...register("comentario")}
                defaultValue={
                  NCE?.verificacaoEficacia?.COMENTARIO
                }
                onChange={handleComentarioChange}
              ></textarea>

              <div className="row">
                <div className="col-md-12 ml-auto">
                  <div className="col-md-3 ml-auto">
                    <label>Número do Plano de Ação:</label>
                    <input
                      type="number"
                      placeholder="Insira o número aqui"
                      disabled={isFieldDisabled()}
                      {...register("numeroDoPlanoDeAcao")}
                      defaultValue={
                        NCE?.verificacaoEficacia?.NUMERO_DO_PLANO_DE_ACAO
                      }
                      onChange={handleNumPlanAcaoChange}
                    />
                  </div>

                  <div className="col-md-3 ml-auto">
                    <label>Concluído por:</label>
                    <input
                      type="text"
                      disabled
                      {...register("user")}
                      defaultValue={NCE?.verificacaoEficacia?.CONCLUIDO_POR ?? "Preenchido automaticamente"}
                      onChange={handleAreaEmitChange}
                    />
                  </div>

                  <div className="col-md-3 ml-auto">
                    <label>Área Emit:</label>
                    <input
                      type="text"
                      placeholder="Insira a área aqui"
                      disabled={isFieldDisabled()}
                      {...register("areaEmit")}
                      defaultValue={NCE?.verificacaoEficacia?.AREA_EMIT}
                      onChange={handleAreaEmitChange}
                    />
                  </div>

                  <div className="col-md-3 ml-auto">
                    <label>Data:</label>
                    <input
                      type="date"
                      placeholder="Insira a data aqui"
                      disabled={isFieldDisabled()}
                      {...register("dataVerificacao")}
                      defaultValue={NCE?.verificacaoEficacia?.DATA}
                      onChange={handleDataVerificacaoChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 ml-auto">
              <div className="row">
                <div className="col-md-12 ml-auto">
                  <button
                    type="button"
                    style={{ float: "left" }}
                    className="btn btn-secondary"
                    onClick={handlePrevStep}
                  >
                    Anterior
                  </button>
                </div>
              </div>
              {habilitaBotao() && (
                <div className="modal-footer">
                  <button
                    type="submit"
                    className="btn btn-success btn-block"
                    style={{ float: "right" }}
                  >
                    Salvar
                  </button>
                  <ToastContainer />
                </div>
              )}
            </div>
          </div>
          <hr />
        </fieldset>
      )}
    </form>
  );
};

export default MultiStepForm;
